    <div class="row">
        <div class=" col-6">
            <h1 class="px-3 px-lg-5 pt-5">Make your online shopping easier with GP Store</h1>
            <div class="px-lg-5 px-3 footer-para">For More Experience Download Our Shopping application on</div>
            <div class="p-lg-5 p-3 d-flex  justify-content-start image">
                <img src="../../assets/play-store/app-store.svg" alt="" class="me-3" style="cursor: pointer;">
                <img src="../../assets/play-store/google-store.svg" alt="" style="cursor: pointer;">
            </div>
        </div>
        <img src="../../assets/play-store/female-food-delivery-worker-with-food-package 1.svg" alt="" class="d-none d-lg-block col-6">
    </div>


<div class="m-lg-5 m-md-4 m-4  about-page
">
    <div class="img-container">
        <div class="bg-img">
            <img [src]="imageUrl" alt="img" class="about-us-img">
            <!-- <div class=""></div> -->
            <!-- <div class="img-content">
                <span class="about-us-heading">{{ pageTitle }}</span>
                <div class="more-about">
                    <div>We can do more for you</div>
                </div>
            </div> -->
        </div>
    </div>
    <!-- <div class="">
        <div class="mt-5 about-us-container">
            <div class="row">
                <div class="col-lg-6 col-12 img-fluid-container">
                    <div class="relative-img">
                        <img src="../../assets/images/about-us-relative-img.svg" alt="img" class="img-fluid">
                    </div>
                    <div class="absolute-img">
                        <img src="../../assets/images/about-us-absolute-img.svg" alt="img" class="img-fluid">
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div>
                        <h1>Know More About Us?</h1>
                    </div>
                    <div class="about-us-details">
                        <div class="mt-4">
                            <h5>Online Grocery Shopping</h5>
                        </div>
                        <div class="mt-4 about-us-details-content">
                            <p>A delivery service that offers on-demand, online grocery shopping. We provide a simple
                                and convenient way to buy groceries anytime, anywhere</p>
                            <p>Let us do the shopping for you! We are on a mission to make online grocery shopping just
                                as easy as it is to buy clothes. Sign up for our weekly grocery delivery service and
                                receive fresh, organic produce, meats, seafood and pantry staples delivered right</p>
                            <p>Get all your groceries online, including organic and non-organic food</p>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-4 col-12">
                            <div>
                                <div class="text-center">
                                    <img src="../../assets/icon/aboutus-choose-product-icon.svg" alt="product-img">
                                </div>
                                <div class="mt-2">
                                    <h6 class="text-center">Choose product</h6>
                                    <div class="details-content">
                                        <p>Browse categories, apply filters, compare prices, and select items for a
                                            streamlined shopping experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div>
                                <div class="text-center">
                                    <img src="../../assets/icon/aboutus-payment-icon.svg" alt="payment-img">
                                </div>
                                <div class="mt-2">
                                    <h6 class="text-center">Make Your Payment</h6>
                                    <div class="details-content">
                                        <p>Choose your preferred payment method, enter details securely, review the
                                            total, and confirm the transaction.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div>
                                <div class="text-center">
                                    <img src="../../assets/icon/aboutus-delivery-icon.svg" alt="delivery-img">
                                </div>
                                <div class="mt-2">
                                    <h6 class="text-center">Fast Delivery</h6>
                                    <div class="details-content">
                                        <p>Track your order, choose delivery times, and receive your groceries at your
                                            doorstep, hassle-free.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                           <div class="col-lg-6 col-12">
<a href="/home" class="btn continue-btn">Continue shopping</a>
</div>
<div class="col-lg-6 col-12">
<a href="/contact-us" class="btn contact-us-btn">Contact us</a>
</div>
 
                    </div>
                </div>
            </div>
        </div>  
    </div>   -->
    <div *ngIf="pageType">
        <div [innerHTML]="pageType.description"></div>
        <app-play-store></app-play-store>

    </div>
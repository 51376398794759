<div class="footer">
  <div class="d-flex flex-wrap justify-content-between p-lg-5 ps-2 pe-5 py-3 ">
    <div class="mb-0 mt-2 col-12 col-lg-3 col-md-6">
      <img src="../../assets/gpstore-logo.svg" alt="">
      <p class="text-wrap py-lg-3 py-2 footer-para">We offer high-quality products and the best produce selection, and the
        ability to get them fresh</p>
      <div class="socialmedia-icon">
        <img src="../../assets/socialmedia-icon/facebook.svg" alt="facebook" class="hover-img">
        <img src="../../assets/socialmedia-icon/x.svg" alt="twitter" class="hover-img">
        <img src="../../assets/socialmedia-icon/instagram.svg" alt="instagram" class="hover-img">
        <img src="../../assets/socialmedia-icon/whatsapp.svg" alt="whatsapp" class="hover-img">
      </div>
    </div>

    <div class="mt-3 col-12 col-lg-2 col-md-6 ">
      <h5>Quick Link</h5>
      <ul class="p-3 arrow-list mb-0" style="cursor: pointer;">
        <li><a href="/home">Home</a></li>
        <li *ngFor="let card of mainCategories; let i = index"><a (click)="categorykey_click(card)"> {{ card.name }}</a></li>
        <!-- <li><a >Food</a></li> -->
        <li><a href="/subscribe-list-pages">Subscription Product</a></li>
      </ul>
    </div>

    <div class="mt-3 col-12 col-lg-3 col-md-6">
      <h5>Our Information</h5>
      <ul class="p-3  arrow-list mb-0" style="cursor: pointer;" >
        <div *ngFor="let page of pagesOutput">
        <li><a (click)="click_pageType(page)" >{{page.title}}</a></li>
      </div>
        <li><a [routerLink]="['/contact-us']">Contact us</a></li>
        <!-- <li><a [routerLink]="['/about-us']">About us</a></li>
        <li><a href="#">Find our stores</a></li>
        <li><a [routerLink]="['/privacy-policy']">Privacy policy</a></li>
        <li><a [routerLink]="['/terms-and-conditions']">Terms & conditions</a></li>
        <li><a [routerLink]="['/cancellation-policy']">Cancellation Policy</a></li> -->
      </ul>
    </div>

    <div class="about-us mt-3 col-12 col-lg-3 col-md-6 ">
      <h5>About us</h5>
      <ul class="mb-0 p-3 about-us-li ">
        <li><a href="#">Harlow</a></li>
        <li class="text-wrap" style="width: 15rem;"><a href="#">Edinburgh Way, Temple Fields, 
          Harlow CM20 2DG</a></li>
        <li><a href="#">447375928153</a></li>
        <li><a href="#">maddmanlimitedgmail.com</a></li>
      </ul>
    </div>
  </div>
  <hr style="width: 95%; margin: auto;">
  <div class="text-center p-3 Copyright">Copyright2024 GPStore</div>
</div>

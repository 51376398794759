import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild,ChangeDetectorRef  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { ProductPopupDialogComponent } from '../product-popup-dialog/product-popup-dialog.component';
import { ProductService } from '../services/product.service';
declare var $: any;
 
@Component({
  selector: 'app-best-selling',
  templateUrl: './best-selling.component.html',
  styleUrls: ['./best-selling.component.css']
})
export class BestSellingComponent implements AfterViewInit {
  type:any ='Food';
  responsiveOptions: any[] | undefined;
  productList: any[] = [];
  categoryList: any[] = []; // List of categories
  productIds: string[] = []; // Property to store category IDs
  categoryMap: { [key: string]: any } = {};
  count: number = 4; // Default number of products to show
  page: number = 1;
  categoryListIds: string[] = []
  categoryname: string | undefined;
  numVisible: number = 4; // default visible items
  mrpPrice: number | undefined;
  priceToShow: number | undefined;
  variationDisplay: string | undefined;
  selectedVariation: any;
  userId: string | null = null;
  add_cart: boolean = true;
  loadMoreRecentlyViewed: boolean = false;
  isLoadingRecentlyViewed: boolean = false;
  quantities = new Map<number, number>();
  addedToCart = new Map<number, boolean>();
  downArrow: boolean = false;
  upArrow: boolean = false;
  rightArrow: boolean = false;
  productListCount:any
  @Input() data: any;
  wishlistIcon = false;
  isLoggedIn: boolean = false;
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  isMobileView = false;
 
 
  @ViewChild('elemt', { static: false }) parentElement!: ElementRef;
  @ViewChild('elemt') elemt!: ElementRef;
 
  constructor(public router: Router, private http: HttpClient, private productService: ProductService, private dialog: MatDialog, public auth: AuthService, private cartService: CartService,private cdr: ChangeDetectorRef) {
    this.auth.accessToken.subscribe((token: string | null) => {
      // Set isLoggedIn to true if token exists, otherwise false
      this.isLoggedIn = !!token;
    });
    this.auth.userInfo.subscribe((data: any) => {
 
      if (data) {
       
        this.userId = data?.id;
        this.isLoggedIn = !!data;
      } else {
        this.isLoggedIn = false;  
      }
    });
 
  }
  ngAfterViewInit(): void {
 
  }
 
  ngOnInit() {
    this.productList.forEach(product => {
      const hasItemsInCart = product.cartitemquantity > 0;
      this.addedToCart.set(product.id, hasItemsInCart);
    });
    if (Array.isArray(this.data)) {
      this.data.forEach(item => {
        this.categoryListCall(item.categorykey);
      });
    } else {
      this.categoryListCall(this.data.categorykey);
      this.categoryname = this.data.name;
    }
 
 
 
    this.updateVisibleItems();
    this.isMobileView = window.innerWidth < 993;
 
  }
 
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVisibleItems();
    this.isMobileView = window.innerWidth < 993;
 
  }
  openProductDialog(product: any): void {
   
    const bestSelling = "bestSelling";
 
    this.dialog.open(ProductPopupDialogComponent, {
      data: {
        id: product.id,
        datafrom: bestSelling,
        variationId: product.selectedVariation
      },
      width: '100%',
      height: '100%',
      disableClose: true
    });
    // product.selectedVariation = ''
  }
 
  startDragging(e: any, flag: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, type: string, child: any, spot: string) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.cursorDown.x = e.pageX;
    this.cursorDown.y = e.pageY;
    if (type === 'mouse') {
      this.startX = e.pageX - el.offsetLeft;
    } else {
      // this.startX = e.touches[0].pageX - el.offsetLeft;
      // this.startY = e.touches[0].pageY - el.offsetTop;
    }
 
 
 
    this.scrollLeft = el.scrollLeft;
    // setTimeout(() => { this.isDragging = true }, 300);
  }
 
  stopDragging(e: any, flag: any, type: string) {
    this.cursorUp.x = e.pageX;
    this.cursorUp.y = e.pageY;
    // setTimeout(() => { this.isDragging = false });
    this.mouseDown = false;
  }
 
  moveEvent(e: any, el: { offsetLeft: number; scrollLeft: number; offsetTop: number }, type: string, child: any) {
    // e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    let x = 0, y = 0;
    if (type === 'mouse') {
      x = e.pageX - el.offsetLeft;
    } else {
      // x = e.touches[0].pageX - el.offsetLeft;
      // y = e.touches[0].pageY - el.offsetTop;
    }
    const scrollX = x - this.startX;
    const scrollY = y - this.startY;
    if (Math.abs(scrollY) > Math.abs(scrollX)) {
      document.body.scrollTo(window.scrollX, window.scrollY - scrollY);
    } else {
      el.scrollLeft = this.scrollLeft - scrollX;
    }
  }
 
 
  onScroll(e: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, child: any) {
    if ((child.clientWidth - el.scrollLeft) <= el.clientWidth + 1) {
      // this.loadMoreProducts();
 
      // if (this.showMoreReview && !this.reviewLoading) {
      //   this.reviewLoading = true;
      //   this.loadmore();
      // }
    }
  }
 
 
 
 
 
 
 
  updateVisibleItems() {
    const width = window.innerWidth;
    if (width > 1199) {
      this.numVisible = 4;
    } else if (width > 991) {
      this.numVisible = 3;
    } else if (width > 767) {
      this.numVisible = 2;
    } else {
      this.numVisible = 1;
    }
  }
 
  get showPrevArrow(): boolean {
    return this.productList.length > this.numVisible;
  }
 
  get showNextArrow(): boolean {
    return this.productList.length > this.numVisible;
  }
  viewAll() {
    this.router.navigate(['/product-list-pages'], { queryParams: { category: this.data.categorykey } });
  }
 
 
  categoryListCall(categoryKey: string): void {
    const initialRequestBody = {
      "status": 1,
      "categoryKey": categoryKey,
    };
    this.http.post(environment.apiUrl + `/categories/list`, initialRequestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          // Assuming the count is available in the response
          const count = data.count ; // Use a default value of 300 if not found in the response
 
          // Now make the second call with the count included
          const requestBodyWithCount = {
            "status": 1,
            "categoryKey": categoryKey,
            "count": count
          };
 
 
    this.http.post(environment.apiUrl + `/categories/list`, requestBodyWithCount, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.categoryList = data.categories_output;
          this.productIds = []; // Initialize productIds here
          this.categoryMap = {}; // Initialize category map
 
          // Extract and store category IDs
          this.categoryList.forEach((category: any) => {
            const categoryList_id = category.id;
            this.productIds.push(categoryList_id); // Store IDs in the property
            this.categoryMap[categoryList_id] = category;
          });
          if (!this.isLoadingRecentlyViewed) {
            this.ProductListCall(this.productIds, this.count, "scroll",'','');
 
          }
 
          // Call ProductListCall with the IDs
        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }
},
);
}
selectSize(productId: any, variation: any): void {
  const product = this.productList.find((item: any) => item.id === productId);
 
  if (product) {
    // Update selected variation details
    product.selectedVariation = variation.productvariationid;
 
    // Handle price logic based on available fields
    if (variation?.priceToShow != null) {
      product.price = variation.priceToShow; // Use priceToShow if available
    } else if (variation?.sellingprice != null) {
      product.price = variation.sellingprice; // Fallback to sellingprice
    } else if (variation?.mrpprice != null) {
      product.price = variation.mrpprice; // Fallback to mrpprice
    } else {
      product.price = 0; // Default to 0 if no price is found
    }
 
    product.originalPrice = variation.mrpprice;
 
    // Use the variation object directly for cartitemsquantity
    if (variation?.cartitemquantity != null) {
      product.cartitemquantity = variation.cartitemquantity;
    } else {
      // Fallback to productvariations if needed
      const selectedVariation = product.productvariations.find(
        (v: any) => v.productvariationid === variation.productvariationid
      );
      product.cartitemquantity = selectedVariation?.cartitemquantity ?? 0;
    }
     // Update wishlist status and icon
     product.wishlistitemstatus =
     variation?.wishlistitemstatus ??
     false; // Default to false if null or undefined
 
   product.wishlistIcon =
     variation?.wishlistitemstatus === 1 ||
     variation?.wishlistIcon === true; // Use wishlistIcon directly if available
 
 
    // Update button visibility based on cart quantity
    this.addedToCart.set(productId, product.cartitemquantity > 0);
 
    // Trigger UI change detection
    this.cdr.detectChanges();
 
  }
}
 
 
 
  loadMoreProducts(productIds: string[], datafrom: any, parentElement:any, rightScroll:any): void {
 
    if (!this.isLoadingRecentlyViewed && this.loadMoreRecentlyViewed) {
 
      this.ProductListCall(productIds, 1, datafrom,parentElement, rightScroll);
    }
  }
 
  // onScrollOrClickArrow(): void {
  //   // this.page += 1;
  //   // this.count =4;
  //   this.loadMoreProducts(this.productIds, "arrow");
 
  // }
 
  // onScrollOrClick() {
  //   this.upArrow = true;
  //   // this.page += 1;
  //   // this.count =4;
  //   const container = this.elemt.nativeElement;
  //   let parentElement = this.elemt.nativeElement.parentElement;
  //   let rightScroll =container.scrollWidth ;
  //   this.loadMoreProducts(this.productIds, "scroll", parentElement, rightScroll);
 
  // }
//   onScrollOrClick() {
//     if (this.isLoadingRecentlyViewed) {
//       return; // Ignore further clicks while loading
//     }
//     this.upArrow = true;
//     if (this.page === 1) {
//       this.page = 5; // Start from 5 on the first click after the initial load
//     } else {
//       this.page += 1; // Increment the page count on subsequent clicks
//     }
//     const container = this.elemt.nativeElement;
   
//     let parentElement = this.elemt.nativeElement.parentElement;
//     let rightScroll =container.scrollWidth ;
//     this.loadMoreProducts(this.productIds, "scroll", parentElement, rightScroll);
//     setTimeout(() => {
//       $(parentElement)[0].scrollTo({
//         left: rightScroll, // Desired scrollLeft value
//         behavior: 'smooth' // Enables smooth scrolling
//       });
//     });
//   }
 
//   onLeftScrollOrClick(parentname: any): void {
//     this.downArrow = false;
//     this.rightArrow = true;
//     const leftBtn = document.querySelector(`#left-selling-button-${parentname}`) as HTMLElement;
//     const content = document.querySelector(`#best-selling-list-${parentname}`) as HTMLElement;
   
//     const scrollLeft = (event: Event) => {
//       if (content.scrollLeft <= 0) {
//         this.downArrow = false; // No more space to scroll left
//         this.rightArrow = true;
//         this.upArrow = false;
//       }
//         content.scrollBy({
//             left: -300,  
//             behavior: 'smooth'  
//         });
//         event.preventDefault();
//     };
//     leftBtn.removeEventListener("click", scrollLeft);
//     leftBtn.addEventListener("click", scrollLeft);
//     scrollLeft(new MouseEvent('click'));
 
// }
 
// onRightScrollOrClick(parentname: any): void {
//   this.rightArrow = true;
//   this.upArrow = true;
//     const rightBtn = document.querySelector(`#right-selling-button-${parentname}`) as HTMLElement;
//     const content = document.querySelector(`#best-selling-list-${parentname}`) as HTMLElement;
//     const scrollRight = (event: Event) => {
//       const maxScrollLeft = content.scrollWidth - content.clientWidth;
     
//       // Check if we can scroll further
//       if (content.scrollLeft + 300 >= maxScrollLeft) {
//         this.rightArrow = false; // No more space to scroll
//       }
//         content.scrollBy({
//             left: +300,  
//             behavior: 'smooth'  
//         });
//         event.preventDefault();
//     };
//     rightBtn.removeEventListener("click", scrollRight);
//     rightBtn.addEventListener("click", scrollRight);
//     scrollRight(new MouseEvent('click'));
 
// }
 
onScrollOrClick() {
  if (this.isLoadingRecentlyViewed) {
    return ; // Ignore further clicks while loading
  }
  this.upArrow = true;
  if (this.page === 1) {
    this.page = 5; // Start from 5 on the first click after the initial load
  } else {
    this.page += 1; // Increment the page count on subsequent clicks
  }
  const container = this.elemt.nativeElement;
  setTimeout(() => {
    if (this.productListCount === this.productList.length) {
      this.downArrow = false;
      this.rightArrow = false;
    }
  }, 0);
  if (this.productList.length > 0 ) {
        this.downArrow = true;  // Enable down arrow
      }
     
      else{
        this.downArrow = false;
          // Enable down arrow
      }
  let parentElement = this.elemt.nativeElement.parentElement;
  let rightScroll =container.scrollWidth ;
  this.loadMoreProducts(this.productIds, "scroll", parentElement, rightScroll);
  setTimeout(() => {
    $(parentElement)[0].scrollTo({
      left: rightScroll, // Desired scrollLeft value
      behavior: 'smooth' // Enables smooth scrolling
    });
  });
}
 
onLeftScrollOrClick(parentname: any): void {
  this.downArrow = false;
  this.rightArrow = true;
  const leftBtn = document.querySelector(`#left-selling-button-${parentname}`) as HTMLElement;
  const content = document.querySelector(`#best-selling-list-${parentname}`) as HTMLElement;
  const scrollLeft = (event: Event) => {
    const TOLERANCE = content.offsetLeft ;
    const scrollLeftMax = content.scrollWidth - content.clientWidth;
 
 
    if (content.scrollLeft <= 0) {
      this.downArrow = false; // No more space to scroll left
      this.rightArrow = true;
      this.upArrow = true;
    this.upArrow = false;
    }else {
      this.upArrow = content.scrollLeft > 300 - TOLERANCE;
 
 
    }
 
   
      content.scrollBy({
          left: -300,  
          behavior: 'smooth'  
      });
      event.preventDefault();
  };
  leftBtn.removeEventListener("click", scrollLeft);
  leftBtn.addEventListener("click", scrollLeft);
  scrollLeft(new MouseEvent('click'));
 
}
 
onRightScrollOrClick(parentname: any): void {
this.rightArrow = true;
this.upArrow = true;
  const rightBtn = document.querySelector(`#right-selling-button-${parentname}`) as HTMLElement;
  const content = document.querySelector(`#best-selling-list-${parentname}`) as HTMLElement;
  const scrollRight = (event: Event) => {
    const maxScrollLeft = content.scrollWidth - content.clientWidth;
    setTimeout(() => {
      if (this.productListCount === this.productList.length) {
        this.downArrow = false;
      }
    }, 0);
    if (content.scrollLeft + 300 >= maxScrollLeft) {
      this.rightArrow = false;
      if (this.productList.length > 0) {
            this.downArrow = true;  // Enable down arrow
          }
         
           else{
            this.downArrow = false;
// Enable down arrow
          }
    }
      content.scrollBy({
          left: +300,  
          behavior: 'smooth'  
      });
      event.preventDefault();
  };
  rightBtn.removeEventListener("click", scrollRight);
  rightBtn.addEventListener("click", scrollRight);
  scrollRight(new MouseEvent('click'));
 
}
 
 
 
 
 
ProductListCall(productIds: string[], count: number, dataFrom: any,parentElement:any, rightScroll:any
): void {
this.isLoadingRecentlyViewed = true;
 
const requestBody: any = {
  status: 1,
  categoryIds: productIds,
  isSale: true,
  page: this.page,
  count: count,
  isMostPopular:"true",
  // sort:"ASES"
};
if (this.userId) {
  requestBody.userId = this.userId;
}
 
this.http.post(environment.apiUrl + `/products/list`, requestBody, { responseType: 'json' }).subscribe(
  (data: any) => {
    if (data.status === 'SUCCESS') {
      data.products_output.forEach((product: any) => {
        // const categoryDetails = this.categoryMap[product.categoryid];
        // if (categoryDetails && categoryDetails.parentname.includes('Food')) {
        //   product.ishalalfood = product.ishalalfood !== null && product.ishalalfood !== undefined;
        // } else  if (categoryDetails && categoryDetails.parentname.includes('Grocery')) {
        //   product.ishalalfood = false; // Halal food not applicable for other categories
        // }
       
        if (product.productvariations && product.productvariations.length > 0) {
          product.productvariations.sort((a: any, b: any) => a.sellingprice - b.sellingprice);
          // product.variations = [];
          product.productvariations.forEach((variation: any, index: number) => {
           
            if (product.productvariations[0]) {
              if (index === 0) {
                if (!variation.cartitemid) {
                  variation.cartitemid = null; // Assign a default value to avoid undefined errors
                }
                // Set the first variation as default
                product.originalPrice = variation.mrpprice;
                product.variationvalue = variation.variationvalue;
                product.price = variation.sellingprice;
                product.variationshortname = variation.variationshortname;
                product.selectedVariation = variation.productvariationid;
                product.cartitemquantity = variation.cartitemquantity;
                product.cartid = variation.cartid;
                product.cartitemid = variation.cartitemid;
                product.wishlistitemstatus = variation.wishlistitemstatus,
                product.wishlistIcon = variation.wishlistitemstatus
              }
             
              // product.variations.push({
              //   originalPrice: variation.mrpprice,
              //   priceToShow: variation.sellingprice,
              //   variationvalue: variation.variationvalue,
              //   variationshortname: variation.variationshortname,
              //   productvariationid: variation.productvariationid
              // });
            }
            // if (!variation.cartitemid) {
            //   variation.cartitemid = null; // Assign a default value to avoid undefined errors
            // }
             // Check if the variation already exists before pushing
             const variationExists = product.productvariations.some(
              (v: any) => v.productvariationid === variation.productvariationid
            );
            if (!variationExists) {
              product.productvariations.push({
                originalPrice: variation.mrpprice,
                priceToShow: variation.sellingprice,
                variationvalue: variation.variationvalue,
                variationshortname: variation.variationshortname,
                productvariationid: variation.productvariationid,
                cartitemid:variation.cartitemid,
                wishlistitemstatus:variation.wishlistitemstatus,
                wishlistIcon:product.wishlistitemstatus
                // cartitemsquantity: variation.cartitemsquantity,
                // cartid : variation.cartid
              });
            }
            product.wishlistIcon=product.wishlistitemstatus
          });
             // Sort variations by variationvalue in ascending order
             product.productvariations.sort((a: any, b: any) => {
              if (a.variationvalue < b.variationvalue) {
                return a.sellingprice - b.sellingprice;
 
                // return -1;
              }
              if (a.variationvalue > b.variationvalue) {
                return a.sellingprice - b.sellingprice;
                // return 1;
              }
              return a.sellingprice - b.sellingprice;
              // return 0;
            });
 
        }
        //  this.wishlistIcon = this.isInWishlist || false;
      });

 data.products_output.forEach((x: any) => {
   x.src = [];
 
   // Parse the `imageinfo` JSON to extract `imageid`
   if (x.imageinfo) {
     try {
       const imageInfoArray = JSON.parse(x.imageinfo);
       if (imageInfoArray.length > 0) {
         const imageId = imageInfoArray[0].imageid; // Assuming you need the first image
         if (imageId) {
           // Call the API to fetch the image
           this.http.get(environment.apiUrl + `/uploads/get?id=${imageId}`).subscribe((res: any) => {
             if (res.status === "SUCCESS") {
               x.src.push(res.uploads.base64data);
             }
           });
         }
       }
     } catch (err) {
       console.error("Error parsing imageinfo JSON:", err);
     }
   }
 });
 
 
      if (dataFrom === "arrow" && this.productList.length > 0) {
        this.upArrow = true; // Show "See Less" after clicking "See More"
      }
      const filteredActivities = data.products_output.filter((activity: any) => {
        return this.productList != activity.id;
      });
 
      this.productList = [...this.productList, ...data.products_output];
      if (parentElement && parentElement != "" && rightScroll && rightScroll != "") {
        this.upArrow = true;
        setTimeout(() => {
          $(parentElement)[0].scrollTo({
            left: rightScroll, // Desired scrollLeft value
            behavior: 'smooth' // Enables smooth scrolling
          });
        });
       
      }
 
      this.productListCount = data.count;
      if (this.productList.length < count * this.page) {
        this.loadMoreRecentlyViewed = false; // No more products to load
      } else {
        this.loadMoreRecentlyViewed = true; // Enable further loading
      }
 
 
      if (data.count > this.productList.length) {
        // this.loadMoreRecentlyViewed = true;
        // this.page += 1;
        // this.upArrow = true; // Hide both arrows if no new products
        this.downArrow = true;
      }
      else {
        // this.loadMoreRecentlyViewed = false;
        this.downArrow = false;
        this.upArrow = true;
 
      }
      this.isLoadingRecentlyViewed = false;
      
 
 
    }
  },
  (error) => {
    console.error("API Error:", error);
  }
);
}

 
  scrollToRight(): void {
 
    if (this.parentElement) {
      this.parentElement.nativeElement.scrollLeft += 200; // Adjust this value as needed
    }
  }
 
  scrollToBottom(): void {
    if (this.parentElement) {
      this.parentElement.nativeElement.scrollTop += 200; // Use this for vertical scrolling
    }
  }
 
  productList_page(productId: string, selectedVariation: string): void {
    const variationId = this.selectedVariation || selectedVariation;
    const bestSelling = "bestSelling";
    const name = this.categoryname;
    this.router.navigate(['/product-pages', productId], {
      state: { dataToPass: { datafrom: bestSelling, variationId: variationId, name:name } }
    });
  }
 
  Add_to_cart(productId: number, productVariationId: string, quantity: number): void {
   
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
      return;
    }
 
    // Find product
    const product = this.productList.find(item => item.id === productId);
   
    if (product) {
      product.cartitemquantity  = quantity; // Update UI immediately
      this.addedToCart.set(productId, quantity > 0); // Show/hide quantity buttons
      // this.addedToCart.set(productId, true); // Show quantity buttons
      this.cdr.detectChanges(); // Trigger change detection
    }
 
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity,
      createdBy: this.userId,
      type:  "is_sale",
 
    };
 
    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          // Update cart ID and new quantity from API response
          const selectedVariation = product?.productvariations.find(
            (v: any) => v.productvariationid === productVariationId
          );
          if (selectedVariation) {
            selectedVariation.cartitemid = data.cartitems?.id;
            selectedVariation.cartid= data.cartitems?.cartid;
            product.cartitemid = data.cartitems?.id;
            product.cartid = data.cartitems?.cartid;
            selectedVariation.cartitemquantity = quantity; // Sync with server response
            product.cartitemquantity = quantity; // Sync with server response
 
          }
 
          // Update cart count in the service
          this.cartService.updateCartCount(data.newCartCount);
          this.cdr.detectChanges(); // Trigger change detection
        }
      },
      (error: any) => {
        console.error('Error in Add_to_cart API call:', error);
      }
    );
  }
 
  getQuantity(productId: number): number {
 
    return this.quantities.get(productId) || 1;
 
  }
 
  increaseQuantity(productId: number, productVariationId: string): void {
    const product = this.productList.find(item => item.id === productId);
    const productvariations = product.productvariations.find(
      (v: any) => v.productvariationid === productVariationId
    );
 
    if (productvariations ) {
      // product.cartitemsquantity += 1;
      // product.cartitemsquantity = (product.cartitemsquantity || 0) + 1;
  // Ensure cartitemsquantity is initialized
  productvariations.cartitemquantity = productvariations.cartitemquantity || 0;
 
  // Increment quantity
  productvariations.cartitemquantity += 1;
      // Update the cart
      this.Add_to_cart(productId, productVariationId, productvariations.cartitemquantity);
      this.cdr.detectChanges(); // Trigger change detection
    }
  }
 
  decreaseQuantity(productId: number, productVariationId: string): void {
    const product = this.productList.find(item => item.id === productId);
    const productvariations = product.productvariations.find(
      (v: any) => v.productvariationid === productVariationId
    );
 
    if (productvariations || product) {
      // Ensure cartitemsquantity is initialized
      productvariations.cartitemquantity = productvariations.cartitemquantity || 0;
      product.cartitemquantity = product.cartitemquantity || 0;
 
      if (product.cartitemquantity > 1 || productvariations.cartitemquantity > 1) {
        // Decrease quantity
        productvariations.cartitemquantity -= 1;
        product.cartitemquantity -= 1;
        // Update the cart with the new quantity
        this.Add_to_cart(productId, productVariationId, productvariations.cartitemquantity);
      } else if (product.cartitemquantity  === 1 || productvariations.cartitemquantity === 1) {
        // If quantity reaches 0, remove the item from the cart
        productvariations.cartitemquantity = 0;
        product.cartitemquantity = 0; 
        this.deleteCartItem(product.cartitemid || productvariations.cartitemid,productvariations.cartid || productvariations.cartitemcartid); // Call the delete API
 
        // Reset UI: Show "Add to Cart" button
        this.addedToCart.set(productId, false);
      }
 
      // Trigger change detection to reflect the updated quantity
      this.cdr.detectChanges();
    } else {
      console.error('Product not found for ID:', productId);
    }
  }
 
 
 
  deleteCartItem(cartitemIds: number | null,cartId: number | null): void {
    if (!cartitemIds || !cartId) {
      console.error('Cannot delete cart item: Cart ID is null.');
      return;
    }
 
    const requestBody = {
      updatedBy: this.userId,
      cartitemIds: [cartitemIds],
      cartId:cartId
    };
 
    this.http.post(environment.apiUrl + `/cart/delete`, requestBody).subscribe({
      next: (response: any) => {
        if (response.status === 'SUCCESS') {
          // Update the cart count if the deletion is successful
          if (this.userId) {
            this.cartService.updateCartCount(response.newCartCount);
          }
        } else {
          // Log failure if the deletion is not successful
          console.error('Failed to delete cart item:', response.message);
        }
      },
      error: (error: any) => {
        // Log the error if the API call fails
        console.error('Error in deleteCartItem API call:', error);
      }
    });
    
  }
 
 
 
  toggleWishList(event: Event, product: any, productId: any, productVariationId: any): void {
    event.stopPropagation(); // Prevent triggering parent events
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
   
    const variation = product.productvariations.find((v: any) => v.productvariationid === productVariationId);
    if (!variation) {
      console.error('Variation not found for Product ID:', productId, 'Variation ID:', productVariationId);
      return;
    }
    const newStatus = !variation.wishlistIcon;
 
    // product.wishlistIcon = newStatus; // Update the wishlist icon status in UI
    variation.wishlistIcon = newStatus; // Update the UI state for the specific variation
    product.wishlistIcon = variation.wishlistIcon; // Update the product wishlist state
 
    if (this.userId) {
      const requestBody = {
        status: newStatus ? 1 : -1,
        createdBy: this.userId,
        userId: this.userId,
        productId: productId,
        productVariationId: productVariationId,
        type:'is_sale'
      };
 
      this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' }).subscribe({
        next: (data: any) => {
          // Handle the success response here if needed
          if (data.status === 'SUCCESS') {
            // Add logic for success handling, if required
          } else {
            console.error('Failed to update wishlist item status:', data.message);
          }
        },
        error: (error) => {
          // Handle error response
          console.error('API Error:', error);
        }
      });
      
    } else {
      // If user is not logged in, navigate to the login page
      this.router.navigate(['/login']);
    }
  }
 

 
 
 
 
}
 
 
 
 
 
 
 
 
 
 
 
 
 
 
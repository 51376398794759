 <div class="m-lg-5 m-md-4 m-4  privacy-policy-page
 ">
    <div class="img-container">
        <div class="bg-img">
            <img  [src]="imageUrl" alt="img" class="privacy-policy-img">
            <!-- <div class="img-overlay"></div>
            <div class="img-content">
                <span class="privacy-policy-condition-heading">{{ pageTitle }}</span>
            </div> -->
        </div>
    </div>
    <!-- <div class="privacy-policy-container">
        <div class="mt-4 privacy-policy-details-content">
            <p>We do not and will never share, disclose, sell, rent, or otherwise provide your personal data to other companies for the marketing of their own products or services.</p>
        </div>
        <div class="process-content">
        <div class="mt-4">
            <h4>How we process these data</h4>
        </div>
        <div class="privacy-policy-details">
            <div class="mt-4 privacy-policy-details-content">
                <p>We use your personally identifiable data (name, email address, etc.) to identify you to contact, send newsletter, and provide products & supports. The non-personal data may be used to improve the service.</p>
            </div>
        </div>
    </div>
    <div class="process-content">
        <div class="mt-4">
            <h4>What rights you have over your data</h4>
        </div>
        <div class="privacy-policy-details">
            <div class="mt-4 privacy-policy-details-content">
                <p>If you have an account on Ionicfirebaseapp site, or leave comments, you can request a copy of your information we have about you. You can also request us to delete your data from our site. This does not include any data we are obliged to keep for security, legal or administrative purposes.</p>
            </div>
        </div>
    </div>
    <div class="process-content">
        <div class="mt-4">
            <h4>Third party links on our site</h4>
        </div>
        <div class="privacy-policy-details">
            <div class="mt-4 privacy-policy-details-content">
                <p>We may talk about third party products and services on our website. These third party sites have their own privacy policies. So, therefore, we have no responsibility or liability for the content and activities of these linked sites.</p>
            </div>
        </div>
    </div>
    <div class="process-content">
        <div class="mt-4">
            <h4>What our cookies do on your browser</h4>
        </div>
        <div class="privacy-policy-details">
            <div class="mt-4 privacy-policy-details-content">
                <p>Ionicfirebaseapp site uses cookies to identify and track visitors, their actions on the site, and their website access preferences. The visitors on ionicfirebaseapp.com who do not wish to have cookies placed on their browsers should turn off cookies before using Ionicfirebaseapp. Once you turn off cookies on your browsers, some features of ionicfirebaseapp.com may not work properly without the aid of cookies.</p>
            </div>
        </div>
    </div>
    <div class="process-content">
        <div class="mt-4">
            <h4>What data breach procedures we have in place</h4>
        </div>
        <div class="privacy-policy-details">
            <div class="mt-4 privacy-policy-details-content">
                <p>If in any case a breach of data such as loss, alteration, unauthorised disclosure or access, personal data transmission, storage or otherwise processed happens, we will notify you immediately after we become aware of.</p>
            </div>
        </div>
    </div>
    <div class="process-content">
        <div class="mt-4">
            <h4>How you can review, update, get a copy or delete your data</h4>
        </div>
        <div class="privacy-policy-details">
            <div class="mt-4 privacy-policy-details-content">
                <p>You can review and update your data on our site at any time by going to your account details. If you want to delete or get a copy of your personal data, send a mail to infoionicfirebaseapp.com . We will do that with immediate effect.</p>
            </div>
        </div>
    </div> 
    <div class="process-content">
        <div class="mt-4">
            <h4>What if we make changes to our Privacy Policy</h4>
        </div>
        <div class="privacy-policy-details">
            <div class="mt-4 privacy-policy-details-content">
                <p>Any change made or any new point added to the Privacy Policy described above will be published here on this page. The major changes will also be informed through newsletter.</p>
            </div>
        </div>
    </div>
    </div> -->
    <div *ngIf="pageType">
        <div [innerHTML]="pageType.description"></div>

<app-play-store></app-play-store>

</div> 

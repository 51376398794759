<div class="banner-img d-md-block d-none" *ngIf="!sessionExpired">
    <button class="close border-0" (click)="closeDialog()">
        <img src="../../assets/icon/close.svg" alt="Close">
    </button>
    <img src="../../assets/play-store/Gp store banner.svg" alt="Banner" class="fit-content store-banner">
    <!-- <img src="../../assets/icon/banner_mobile.svg" alt="Mobile Banner" class=" mobile-banner"> -->
    <div class="play-store-img d-flex">
        <img src="../../assets/play-store/app-store.svg" alt="App Store" class="me-2">
        <img src="../../assets/play-store/google-store.svg" alt="Google Store">
    </div>
</div>
<div class="d-block d-md-none banner-mob-img d-flex flex-column justify-content-between h-auto">
    <div class="height-div">
    <!-- Close Button -->
    <button class="close border-0" (click)="closeDialog()">
        <img src="../../assets/icon/close.svg" alt="Close">
    </button>
 
    <!-- Banner Image -->
    <img src="../../assets/images/gpstore_banner.svg" alt="Banner" class="fit-content banner-img-mob">
</div>
    <!-- Footer Section -->
    <footer >
        <h6 class="fw-bold mb-2 text-center">Available On</h6>
        <div class="row">
            <img src="../../assets/play-store/app-store.svg" alt="App Store" class="col-6">
            <img src="../../assets/play-store/google-store.svg" alt="Google Store" class="col-6">
        </div>
    </footer>
</div>
 
 
 
 
 
 
 
 
 
 
<!-- <div class="banner-img d-none d-md-block">
    <button class="close border-0" (click)="closeDialog()"><img src="../../assets/icon/close.svg" alt=""></button>
        <img src="../../assets/play-store/Gp store banner.svg" alt="" class="fit-content ">
    <div class="play-store-img ">
        <img src="../../assets/play-store/app-store.svg" alt="" class="me-2">
        <img src="../../assets/play-store/google-store.svg" alt="">
    </div>
</div>
 
<div class="banner-img d-md-none d-block">
    <button class="close border-0" (click)="closeDialog()">
        <img src="../../assets/icon/close.svg" alt="Close">
    </button>
    <img src="../../assets/icon/banner_mobile.svg" alt="Mobile Banner" class=" mobile-banner">
    <div class="play-store-img">
        <img src="../../assets/play-store/app-store.svg" alt="App Store" class="me-2">
        <img src="../../assets/play-store/google-store.svg" alt="Google Store">
    </div>
</div> -->
 
 
 
 
 
 
 
<!-- <div class="banner-img d-none d-md-block">
    <button class="close border-0" (click)="closeDialog()"><img src="../../assets/icon/close.svg" alt=""></button>
        <img src="../../assets/play-store/Gp store banner.svg" alt="" class="fit-content ">
    <div class="play-store-img ">
        <img src="../../assets/play-store/app-store.svg" alt="" class="me-2">
        <img src="../../assets/play-store/google-store.svg" alt="">
    </div>
</div>
 
<div class="banner-img d-md-none d-block">
    <button class="close border-0" (click)="closeDialog()">
        <img src="../../assets/icon/close.svg" alt="Close">
    </button>
    <img src="../../assets/icon/banner_mobile.svg" alt="Mobile Banner" class=" mobile-banner">
    <div class="play-store-img">
        <img src="../../assets/play-store/app-store.svg" alt="App Store" class="me-2">
        <img src="../../assets/play-store/google-store.svg" alt="Google Store">
    </div>
</div> -->
 
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
 
@Component({
  selector: 'app-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.css']
})
export class ContactUsPageComponent {
  contactForm = {
    name: '',
    email: '',
    phone: '',
    message: ''
  };
  constructor(private http: HttpClient){  }
 ngOnInit():void{
  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
        })
 
 }
 contactUs() {
  const requestBody = { ...this.contactForm };
 
  this.http.post(environment.apiUrl + `/users/contactus`, requestBody, { responseType: 'json' })
  .subscribe({
    next: (data: any) => {
      if (data.status === 'SUCCESS') {
        alert('Message sent successfully!');
        this.resetForm();
      } else {
        console.error('Failed to send message:', data.message);
      }
    },
    error: (error) => {
      console.error('Contact Us API Error:', error);
    }
  });

}
resetForm() {
  this.contactForm = {
    name: '',
    email: '',
    phone: '',
    message: ''
  };
}
}
 
 
 <div class="m-lg-5 m-md-4 m-4 terms-page">
        <div class="img-container">
            <div class="bg-img">
                <img [src]="imageUrl" alt="img" class="terms-condition-img">
                <!-- <div class="img-overlay"></div>
                <div class="img-content">
                    <span class="terms-condition-heading">{{ pageTitle }}</span>
                </div> -->
            </div>
        </div>
        <!-- <div class="terms-conditions-container">
            <div class="mt-4">
                <h4>TERMS AND CONDITIONS</h4>
            </div>
            <div class="terms-conditions-details">
                <div class="mt-4 terms-conditions-details-content">
                    <p>These terms and conditions outline the rules and regulations for the use of Gilden Park Grocers's
                        Website.</p>
                    <p>By accessing this website, we assume you accept these terms and conditions in full. Do not continue
                        to uu Gilden Park Grocers's Website. Foods's website if you do not accept all of the terms and
                        conditions stated on this page.</p>
                    <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer
                        Notice and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing
                        this website and accepting the Company's terms and conditions. "The Company", "Ourselves", "We",
                        "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and
                        ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and
                        consideration of payment necessary to undertake the process of our assistance to the Client in the
                        most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the
                        express purpose of meeting the Client's needs in respect of provision of the Company's stated
                        services/products, in accordance with and subject to, prevailing law of United Kingdom. Any use of
                        the above terminology or other words in the singular, plural, capitalisation and/or he/she or they,
                        are taken as interchangeable and therefore as referring to same.</p>
                </div>
            </div>
        </div>
        <div class="alcohol-policy-container">
            <div class="mt-4">
                <h4>SALE OF ALCOHOL POLICY</h4>
            </div>
            <div class="alcohol-policy-details">
                <div class="mt-4 alcohol-policy-details-content">
                    <p>Due to UK alcohol licensing laws we may require you to show ID to verify you are aged 18 or over. We
                        operate a Challenge 21 schemes so proof of age will be requested at time of delivery if you look
                        under 21 years old. Accepted forms of ID are a Passport or a Photo Card Drivers Licence. The
                        delivery drivers decision is final.</p>
                </div>
            </div>
        </div>
        <div class="cookies-container">
            <div class="mt-4">
                <h4>COOKIES</h4>
            </div>
            <div class="cookies-details">
                <div class="mt-4 cookies-details-content">
                    <p>We employ the use of cookies. By using</p>
                    <p>Gilden Park Grocers's Website.'s website you consent to the use of cookies in accordance with</p>
                    <p>Gilden Park Grocers's Website.'s privacy policy.</p>
                    <p>Most of the modern day interactive websites use cookies to enable us to retrieve user details for
                        each visit. Cookies are used in some areas of our site to enable the functionality of this area and
                        ease of use for those people visiting. Some of our affiliate / advertising partners may also use
                        cookies.</p>
                </div>
            </div>
        </div>
        <div class="return-container">
            <div class="mt-4">
                <h4>OUR RETURN POLICY</h4>
            </div>
            <div class="return-details">
                <div class="mt-4 return-details-content">
                    <p>Our Return Policy</p>
                    <p>We aim to provide a great experience every time, but even we can experience the off day occasionally.
                        The best way to guarantee a great experience is to review our shipping guidelines and keep them in
                        mind when placing orders.</p>
                    <p>If you have an issue that isn't covered below, please reach out to our team and we'll help work
                        through a solution.</p>
                    <p>Thanks!</p>
                    <p>
                    <div>
                        <p><b class="fw-bold">Shipping Accuracy</b> Check your addresses carefully! Our delivery guarantee extends only to correctly addressed
                            orders. We cannot guarantee the condition of the package if the delivery service has to reroute.
                            We guarantee delivery only to the provided address confirmed on the order acknowledgement.</p>
                    </div>

                    
                    <div>
                                            <p><b class="fw-bold">Refunds and Replacement</b> Due to the perishable nature of our product, we do not accept
    
    
                        <p>Due to the perishable nature of our product, we do not accept
                        returns. In the event you are dissatisfied with your purchase, you must contact us within 30 days
                        from receiving your order. We reserve the right to limit refunds and replacements, and we can only
                        offer one replacement per consumer.</p>
                    </div>
                
                    <p>
                        <p><b class="fw-bold">Handling and Freshness</b>
    All products shipped from The Health Food Store are shipped frozen
                        from our warehouse.</p>
                    <p>Please freeze the products immediately upon receipt. Products must remain frozen until they are ready
                        to be consumed. Failure to report spoilage within 48 hours of receiving the order will be at the
                        loss of the consumer. Other defects must be reported within 30 days from receipt of order. We are
                        committed to using only the highest quality ingredients. Our products have no artificial
                        preservatives or coloring and properly stored in the freezer will remain fresh and delicious for
                        approximately nine months</p>
                </div>
            </div>
        </div> -->
    
    <div *ngIf="pageType">
        <div [innerHTML]="pageType.description"></div>
<app-play-store></app-play-store>
</div>  

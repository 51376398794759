import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgImageSliderComponent } from 'ng-image-slider';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { ProductPopupDialogComponent } from '../product-popup-dialog/product-popup-dialog.component';
import { ProductService } from '../services/product.service';
import { Subscription } from 'rxjs';
declare var $: any;
 
@Component({
  selector: 'app-subscribe-product',
  templateUrl: './subscribe-product.component.html',
  styleUrls: ['./subscribe-product.component.css']
})
export class SubscribeProductComponent implements OnInit, OnDestroy{
  responsiveOptions: any[] | undefined;
  productList: any[] = [];
  productIds: string[] = [];
  numVisible: number = 4; // default visible items
  mrpPrice: number | undefined;
  priceToShow: number | undefined;
  selectedVariation: any;
  userId: string | null = null;
  add_cart: boolean = true;
  isLoggedIn: boolean = false;
  loadMoreRecentlyViewed: boolean = false;
  isLoadingRecentlyViewed: boolean = false;
  quantities = new Map<number, number>();
  addedToCart = new Map<number, boolean>();
  count: number = 4; // Default number of products to show
  page: number = 1;
  downArrow: boolean = false;
  upArrow: boolean = false;
  rightArrow: boolean = false;
  productListCount:any
 
  wishlistIcon = false;
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  isMobileView = false;
 
  private subscription: Subscription = new Subscription();
  @ViewChild('elemt', { static: false }) parentElement!: ElementRef;
  @Input() searchQuery: string | null = null;
  @ViewChild('elemt') elemt!: ElementRef;
  constructor(public router: Router, private http: HttpClient, private productService: ProductService, private dialog: MatDialog, public auth: AuthService, private cartService: CartService,private cdr: ChangeDetectorRef) {
    this.auth.accessToken.subscribe((token: string | null) => {
      // Set isLoggedIn to true if token exists, otherwise false
      this.isLoggedIn = !!token;
    });
    this.auth.userInfo.subscribe((data: any) => {
      if (data) {
        this.userId = data?.id;
        this.isLoggedIn = !!data;
      } else {
        this.isLoggedIn = false;
      }
    });
 
  }
  ngOnInit() {
   
    this.subscription.add(
      this.productService.getData().subscribe(searchQuery => {
        if (searchQuery) {
          this.searchQuery=searchQuery
          this.productList=[];
          this.ProductListCall(this.count,this.searchQuery, "scroll","");
 
        }
      })
    );
    if (!this.isLoadingRecentlyViewed) {
      this.ProductListCall(this.count, "scroll", '', '');
 
    }
    this.updateVisibleItems();
    this.isMobileView = window.innerWidth < 993;
 
  }
 
  ngOnDestroy(): void {
    // Clean up the subscription when the component is destroyed
    this.subscription.unsubscribe();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVisibleItems();
    this.isMobileView = window.innerWidth < 993;
 
  }
 
  openProductDialog(product: any, selectedVariationId: string): void {
 
    const subscribeProduct = "subscribeProduct";
    const variationId = this.selectedVariation || selectedVariationId;
    this.dialog.open(ProductPopupDialogComponent, {
      data: {
        id: product.id,
        datafrom: subscribeProduct,
        variationId: variationId
      },
      width: '100%',
      height: '100%',
      disableClose: true
    });
    this.selectedVariation = ''
  }
 
  updateVisibleItems() {
    const width = window.innerWidth;
    if (width > 1199) {
      this.numVisible = 4;
    } else if (width > 991) {
      this.numVisible = 3;
    } else if (width > 767) {
      this.numVisible = 2;
    } else {
      this.numVisible = 1;
    }
  }
 
  get showPrevArrow(): boolean {
    return this.productList.length > this.numVisible;
  }
 
  get showNextArrow(): boolean {
    return this.productList.length > this.numVisible;
  }
  selectSize(productId: any, variation: any): void {
    const product = this.productList.find((item: any) => item.id === productId);
   
    if (product) {
      // Update selected variation details
      product.selectedVariation = variation.productvariationid;
   
      // Handle price logic based on available fields
      if (variation?.priceToShow != null) {
        product.price = variation.priceToShow; // Use priceToShow if available
      } else if (variation?.priceupto7days != null) {
        product.price = variation.priceupto7days; // Fallback to priceupto7days
      } else if (variation?.mrpprice != null) {
        product.price = variation.mrpprice; // Fallback to mrpprice
      } else {
        product.price = 0; // Default to 0 if no price is found
      }
   
      product.originalPrice = variation.mrpprice;
     // Update wishlist status and icon
     product.wishlistitemstatus =
     variation?.wishlistitemstatus ??
     false; // Default to false if null or undefined
 
   product.wishlistIcon =
     variation?.wishlistitemstatus === 1 ||
     variation?.wishlistIcon === true; // Use wishlistIcon directly if available
 
      // product.wishlistIcon = variation?.wishlistIcon ?? false;
      // Trigger UI change detection
      this.cdr.detectChanges();
   
    }
  }
 
  startDragging(e: any, flag: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, type: string, child: any, spot: string) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.cursorDown.x = e.pageX;
    this.cursorDown.y = e.pageY;
    if (type === 'mouse') {
      this.startX = e.pageX - el.offsetLeft;
    } else {
      // this.startX = e.touches[0].pageX - el.offsetLeft;
      // this.startY = e.touches[0].pageY - el.offsetTop;
    }
 
 
 
    this.scrollLeft = el.scrollLeft;
    // setTimeout(() => { this.isDragging = true }, 300);
  }
 
  stopDragging(e: any, flag: any, type: string) {
    this.cursorUp.x = e.pageX;
    this.cursorUp.y = e.pageY;
    // setTimeout(() => { this.isDragging = false });
    this.mouseDown = false;
  }
 
  moveEvent(e: any, el: { offsetLeft: number; scrollLeft: number; offsetTop: number }, type: string, child: any) {
    // e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    let x = 0, y = 0;
    if (type === 'mouse') {
      x = e.pageX - el.offsetLeft;
    } else {
      // x = e.touches[0].pageX - el.offsetLeft;
      // y = e.touches[0].pageY - el.offsetTop;
    }
    const scrollX = x - this.startX;
    const scrollY = y - this.startY;
    if (Math.abs(scrollY) > Math.abs(scrollX)) {
      document.body.scrollTo(window.scrollX, window.scrollY - scrollY);
    } else {
      el.scrollLeft = this.scrollLeft - scrollX;
    }
  }
 
 
  onScroll(e: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, child: any) {
    if ((child.clientWidth - el.scrollLeft) <= el.clientWidth + 1) {
      // this.loadMoreProducts();
 
      // if (this.showMoreReview && !this.reviewLoading) {
      //   this.reviewLoading = true;
      //   this.loadmore();
      // }
    }
  }
 
  loadMoreProducts(productIds: string[], datafrom: any, parentElement: any, rightScroll: any): void {
 
    if (!this.isLoadingRecentlyViewed && this.loadMoreRecentlyViewed) {
 
      this.ProductListCall(1, datafrom, parentElement, rightScroll);
    }
  }
 
 
  // onScrollOrClick() {
  //   this.upArrow = true;
 
  //   // this.page += 1;
  //   // this.count =4;
  //   const container = this.elemt.nativeElement;
  //   let parentElement = this.elemt.nativeElement.parentElement;
  //   let rightScroll = container.offsetWidth - container.offsetLeft;
  //   this.loadMoreProducts(this.productIds, "scroll", parentElement, rightScroll);
 
  // }
  // onLeftScrollOrClick(): void {
 
  //   const leftBtn = document.querySelector('#left-subscribe') as HTMLElement;
  //   const content = document.querySelector('#subscribe-list') as HTMLElement;
  //   const scrollLeft = (event: Event) => {
  //     content.scrollBy({
  //       left: -300,
  //       behavior: 'smooth'
  //     });
  //     event.preventDefault();
  //   };
  //   leftBtn.removeEventListener("click", scrollLeft);
  //   leftBtn.addEventListener("click", scrollLeft);
  //   scrollLeft(new MouseEvent('click'));
  // }
  // onRightScrollOrClick(): void {
  //   const rightBtn = document.querySelector('#right-subscribe') as HTMLElement;
  //   const content = document.querySelector('#subscribe-list') as HTMLElement;
  //   const scrollRight = (event: Event) => {
  //     content.scrollBy({
  //       left: +300,
  //       behavior: 'smooth'
  //     });
  //     event.preventDefault();
  //   };
  //   rightBtn.removeEventListener("click", scrollRight);
  //   rightBtn.addEventListener("click", scrollRight);
  //   scrollRight(new MouseEvent('click'));
  // }
  // onLeftScrollOrClick(): void {
  //   this.downArrow = false;
  //   this.rightArrow = true;
   
  //   const leftBtn = document.querySelector('#left-subscribe') as HTMLElement;
  //   const content = document.querySelector('#subscribe-list') as HTMLElement;
   
  //   const scrollLeft = (event: Event) => {
     
  //     // Check if we are at the start of the scrollable content
  //     if (content.scrollLeft <= 0) {
  //       this.downArrow = false; // No more space to scroll left
  //       this.rightArrow = true;
  //       this.upArrow = false;
  //     }
   
  //     content.scrollBy({
  //       left: -300,
  //       behavior: 'smooth'
  //     });
   
  //     event.preventDefault();
  //   };
   
  //   leftBtn.removeEventListener("click", scrollLeft);
  //   leftBtn.addEventListener("click", scrollLeft);
  //   scrollLeft(new MouseEvent('click'));
  // }
   
  // onRightScrollOrClick(): void {
  //   this.rightArrow = true;
  //   this.upArrow = true;
  //   const rightBtn = document.querySelector('#right-subscribe') as HTMLElement;
  //   const content = document.querySelector('#subscribe-list') as HTMLElement;
   
  //   const scrollRight = (event: Event) => {
  //     const maxScrollLeft = content.scrollWidth - content.clientWidth;
     
  //     // Check if we can scroll further
  //     if (content.scrollLeft + 300 >= maxScrollLeft) {
  //       this.rightArrow = false; // No more space to scroll
  //     }
   
  //     content.scrollBy({
  //       left: +300,
  //       behavior: 'smooth'
  //     });
  //     event.preventDefault();
  //   };
   
  //   rightBtn.removeEventListener("click", scrollRight);
  //   rightBtn.addEventListener("click", scrollRight);
  //   scrollRight(new MouseEvent('click'));
  // }
   
  onScrollOrClick() {
    if (this.isLoadingRecentlyViewed) {
      return ; // Ignore further clicks while loading
    }
    this.upArrow = true;
    if (this.page === 1) {
      this.page = 5; // Start from 5 on the first click after the initial load
    } else {
      this.page += 1; // Increment the page count on subsequent clicks
    }
    const container = this.elemt.nativeElement;
    setTimeout(() => {
      if (this.productListCount === this.productList.length) {
        this.downArrow = false;
        this.rightArrow = false;
      }
    }, 0);
    if (this.productList.length > 0 ) {
          this.downArrow = true;  // Enable down arrow
        }
       
        else{
          this.downArrow = false;
            // Enable down arrow
        }
    let parentElement = this.elemt.nativeElement.parentElement;
    let rightScroll =container.scrollWidth ;
    this.loadMoreProducts(this.productIds, "scroll", parentElement, rightScroll);
    setTimeout(() => {
      $(parentElement)[0].scrollTo({
        left: rightScroll, // Desired scrollLeft value
        behavior: 'smooth' // Enables smooth scrolling
      });
    });
  }
 
  onLeftScrollOrClick(): void {
    this.downArrow = false;
    this.rightArrow = true;
     const leftBtn = document.querySelector('#left-subscribe') as HTMLElement;
    const content = document.querySelector('#subscribe-list') as HTMLElement;
    const scrollLeft = (event: Event) => {
      const TOLERANCE = content.offsetLeft ;
      const scrollLeftMax = content.scrollWidth - content.clientWidth;
 
 
      if (content.scrollLeft <= 0) {
        this.downArrow = false; // No more space to scroll left
        this.rightArrow = true;
        this.upArrow = true;
      this.upArrow = false;
      }else {
        // this.upArrow = false;
        this.upArrow = content.scrollLeft > 300 - TOLERANCE;
        // this.downArrow = content.scrollLeft + 300 < scrollLeftMax;
        // if (isFirstItemVisible) {
        //   this.upArrow = false;
        // } else {
        //   this.upArrow = true;
        // }
 
 
      }
      // if (content.scrollLeft <= 0) {
      //   this.downArrow = false; // No more space to scroll left
      //   this.rightArrow = true;
      //   this.upArrow = true;
      // } else if ( content.scrollLeft < 300) {
      //   // this.downArrow = true;
      //   // this.rightArrow = true;
      //   this.upArrow = true; // Enable the up arrow
      // }
     
        content.scrollBy({
            left: -300,  
            behavior: 'smooth'  
        });
        event.preventDefault();
    };
    leftBtn.removeEventListener("click", scrollLeft);
    leftBtn.addEventListener("click", scrollLeft);
    scrollLeft(new MouseEvent('click'));
 
}
 
onRightScrollOrClick(): void {
  this.rightArrow = true;
  this.upArrow = true;
   const rightBtn = document.querySelector('#right-subscribe') as HTMLElement;
    const content = document.querySelector('#subscribe-list') as HTMLElement;
    const scrollRight = (event: Event) => {
      const maxScrollLeft = content.scrollWidth - content.clientWidth;
      setTimeout(() => {
        if (this.productListCount === this.productList.length) {
          this.downArrow = false;
        }
      }, 0);
      if (content.scrollLeft + 300 >= maxScrollLeft) {
        this.rightArrow = false;
        if (this.productList.length > 0) {
              this.downArrow = true;  // Enable down arrow
            }
           
             else{
              this.downArrow = false;
  // Enable down arrow
            }
      }
        content.scrollBy({
            left: +300,  
            behavior: 'smooth'  
        });
        event.preventDefault();
    };
    rightBtn.removeEventListener("click", scrollRight);
    rightBtn.addEventListener("click", scrollRight);
    scrollRight(new MouseEvent('click'));
 
}
 
ProductListCall(count: number, dataFrom: any, parentElement: any, rightScroll: any
): void {
  this.isLoadingRecentlyViewed = true;
 
  const requestBody: any = {
    status: 1,
    isSubscribe: true,
    page: this.page,
    count: count
  };
  if (this.userId) {
    requestBody.userId = this.userId;
  }
  if(this.searchQuery){
    requestBody.q = this.searchQuery
  }
 
  this.http.post(environment.apiUrl + `/products/list`, requestBody, { responseType: 'json' }).subscribe(
    (data: any) => {
      if (data.status === 'SUCCESS') {
 
        data.products_output.forEach((product: any) => {
          if (product.productvariations && product.productvariations.length > 0) {
            product.productvariations.sort((a: any, b: any) => a.priceupto7days - b.priceupto7days);
 
            // product.variations  = [];
            product.productvariations.forEach((variation: any, index: number) => {
              if (product.productvariations[0]) {
                if (index === 0) {
                  product.originalPrice = variation.mrpprice;
                  product.variationvalue = variation.variationvalue;
                  product.price = variation.priceupto7days;
                  product.variationshortname = variation.variationshortname;
                  product.selectedVariation = variation.productvariationid;
                  product.wishlistitemstatus = variation.wishlistitemstatus,
                  product.wishlistIcon = variation.wishlistitemstatus
                // Set the first variation as default
                // product.originalPrice = variation.mrpprice;
                // product.variationvalue = variation.variationvalue;
                // product.price = variation.priceupto7days;
                // // product.originalPrice = variation.mrpprice;
                // product.variationvalue = variation.variationvalue;
                // product.variationshortname = variation.variationshortname;
                // product.wishlistitemstatus = variation.wishlistitemstatus;
                // product.productvariationid= variation.productvariationid;
 
                // product.selectedVariation = variation.productvariationid
                // product.cartquantity = variation.cartquantity
                // product.cartid = variation.cartid,
                // product.selectedVariation = variation.productvariationid
                // product.productvariations.push({
                //   originalPrice: variation.mrpprice,
                //   priceToShow: variation.priceupto7days,
                //   variationvalue: variation.variationvalue,
                //   variationshortname: variation.variationshortname,
                //   productvariationid: variation.productvariationid,
                //   wishlistitemstatus:variation.wishlistitemstatus
                // });
 
              }
              }
              // Check if the variation already exists before pushing
              const variationExists = product.productvariations.some(
                (v: any) => v.productvariationid === variation.productvariationid
            );
            if (!variationExists) {
              product.productvariations.push({
                originalPrice: variation.mrpprice,
                priceToShow: variation.priceupto7days,
                variationvalue: variation.variationvalue,
                variationshortname: variation.variationshortname,
                productvariationid: variation.productvariationid,
                wishlistitemstatus:variation.wishlistitemstatus,
                wishlistIcon:product.wishlistitemstatus
              });
            }
            product.wishlistIcon=product.wishlistitemstatus
            });
           
            // Sort variations by variationvalue in ascending order
            product.productvariations.sort((a: any, b: any) => {
              if (a.variationvalue < b.variationvalue) {
                return a.priceupto7days - b.priceupto7days;
                // return -1;
              }
              if (a.variationvalue > b.variationvalue) {
                return a.priceupto7days - b.priceupto7days;
                // return 1;
              }
              return a.priceupto7days - b.priceupto7days;
              // return 0;
            });
          }
          //  this.wishlistIcon = this.isInWishlist || false;
        });
 
        data.products_output.forEach((x: any) => {
          x.src = [];
        
          // Parse and use the `imageinfo` field directly
          if (x.imageinfo) {
            try {
              const imageInfoArray = JSON.parse(x.imageinfo);
              if (imageInfoArray.length > 0) {
                const imageId = imageInfoArray[0].imageid; // Assuming you want the first image
        
                if (imageId) {
                  // Fetch the image using `uploads/get`
                  this.http.get(environment.apiUrl + `/uploads/get?id=${imageId}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      x.src.push(res.uploads.base64data);
                    }
                  });
                }
              }
            } catch (err) {
              console.error("Error parsing imageinfo JSON:", err);
            }
          }
        });
        
 
        if (dataFrom === "arrow" && this.productList.length > 0) {
          this.upArrow = true; // Show "See Less" after clicking "See More"
        }
        const filteredActivities = data.products_output.filter((activity: any) => {
          return this.productList != activity.id;
        });
 
        this.productList = [...this.productList, ...data.products_output];
        if (parentElement && parentElement != "" && rightScroll && rightScroll != "") {
     this.upArrow = true;
 
          setTimeout(() => {
            $(parentElement)[0].scrollTo({
              left: rightScroll, // Desired scrollLeft value
              behavior: 'smooth' // Enables smooth scrolling
            });
          });
 
        }
 
 
        this.productListCount = data.count;
        if (this.productList.length < count * this.page) {
          this.loadMoreRecentlyViewed = false; // No more products to load
        } else {
          this.loadMoreRecentlyViewed = true; // Enable further loading
        }
 
 
        if (data.count > this.productList.length) {
          // this.loadMoreRecentlyViewed = true;
          // this.page += 1;
          // this.upArrow = true; // Hide both arrows if no new products
          this.downArrow = true;
        }
        else {
          // this.loadMoreRecentlyViewed = false;
          this.downArrow = false;
          this.upArrow = true;
 
        }
        this.isLoadingRecentlyViewed = false;
 
 
      }
    },
    (error) => {
      console.error("API Error:", error);
    }
  );
}
 
 
  productList_page(productId: string, selectedVariation: string): void {
 
    const subscribeProduct = "subscribeProduct";
 
    const variationId = this.selectedVariation || selectedVariation;
    this.router.navigate(['/product-pages', productId], {
      state: { dataToPass: { datafrom: subscribeProduct, variationId: variationId } }
    });
    // const variationId = this.selectedVariation || selectedVariation;
    // const bestSelling = "bestSelling";
    // // const name = this.categoryname;
    // this.router.navigate(['/product-pages', productId], {
    //   state: { dataToPass: { datafrom: bestSelling, variationId: variationId} }
    // });
 
  }
  SubcribeClick(product_id: any, selectedVariationId: any) {
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const variationId = this.selectedVariation || selectedVariationId;
 
    const subcribe = "SubcribeProduct"
    this.router.navigate(['/add-cart'], {
      state: {
        dataToPass: {
          datafrom: subcribe,
          product_id: product_id,
          variation_id: variationId
        }
      }
    });
  }
  subscribeList_page(): void {
    this.router.navigate(['/subscribe-list-pages']);
  }
  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity,
      createdBy: this.userId,
      type:  "is_subscribe",
 
    };
 
    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe({
      next:(data: any) => {
        if (data.status === 'SUCCESS') {
          const product = this.productList.find(item => item.id === productId);
          if (product) {
            product.cartquantity = quantity;
          }
 
          this.addedToCart.set(productId, true);
          this.cartService.updateCartCount(data.newCartCount);
 
        }
      },
     error: (error: any) => {
        console.error("Error in cartList API call:", error);
      }
  });
  }
 
  getQuantity(productId: number): number {
 
    return this.quantities.get(productId) || 1;
 
  }
  increaseQuantity(productId: number, productVariationId: string) {
    const product = this.productList.find(item => item.id === productId);
    if (product) {
      product.cartquantity += 1;
      this.Add_to_cart(productId, productVariationId, product.cartquantity);
    }
  }
 
  decreaseQuantity(productId: number, productVariationId: string, cartId: any) {
    const product = this.productList.find(item => item.id === productId);
 
    if (product) {
      if (product.cartquantity > 1) {
        // Decrease quantity and update the cart
        product.cartquantity -= 1;
        this.Add_to_cart(productId, productVariationId, product.cartquantity);
      } else if (product.cartquantity === 1) {
        // If the quantity is 1, set it to 0 and call delete function
        product.cartquantity = 0;
        this.deleteCartItem(cartId);  // Call delete function
      }
    }
  }
 
  scrollToRight(): void {
 
    if (this.parentElement) {
      this.parentElement.nativeElement.scrollLeft += 200; // Adjust this value as needed
    }
  }
 
  scrollToBottom(): void {
    if (this.parentElement) {
      this.parentElement.nativeElement.scrollTop += 200; // Use this for vertical scrolling
    }
  }
  deleteCartItem(cartId: any): void {
    const requestBody = {
      updatedBy: this.userId,
      cartIds: [cartId],
    };
 
    this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe({
      next:(data: any) => {
        if (data.status === 'SUCCESS') {
          if (this.userId !== null && this.userId !== undefined) {
            this.cartService.updateCartCount(this.userId);
          } else {
            console.error('User ID is null or undefined. Cannot update cart count.');
          }
        }
      },
      error:(error: any) => {
        console.error('An error occurred:', error);
      }
  });
  }
 
 
  toggleWishList(event: Event, product: any, productId: any, productVariationId: any): void {
    event.stopPropagation(); // Prevent triggering parent events
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const variation = product.productvariations.find((v: any) => v.productvariationid === productVariationId);
    if (!variation) {
      console.error('Variation not found for Product ID:', productId, 'Variation ID:', productVariationId);
      return;
    }
    const newStatus = !variation.wishlistIcon;
    variation.wishlistIcon = newStatus; // Update the UI state for the specific variation
    product.wishlistIcon = variation.wishlistIcon; // Update the product wishlist state
   
    // product.wishlistIcon = newStatus; // Update the wishlist icon status in UI
    if (this.userId) {
      const requestBody = {
        status: newStatus ? 1 : -1,
        createdBy: this.userId,
        userId: this.userId,
        productId: productId,
        productVariationId: productVariationId,
        type:'is_subscribe'
      };
 
      this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
        .subscribe({
          next:(data: any) => {
            // Handle the success response here if needed
          },
          error:(error) => {
            console.error("API Error:", error);
          }
    });
    } else {
      // If user is not logged in, navigate to the login page
      this.router.navigate(['/login']);
    }
  }
 
 
 
}
 
 
 
 
 
 
 
 
 
import { Component, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-gpstore-banner',
  templateUrl: './gpstore-banner.component.html',
  styleUrls: ['./gpstore-banner.component.css']
})
export class GpstoreBannerComponent {
  sessionExpired =false;
 
  constructor(public dialogRef: MatDialogRef<GpstoreBannerComponent>,private renderer: Renderer2) {  
    const sessionExpired = localStorage.getItem('sessionExpired') === 'true';
    if (sessionExpired) {
      this.hideBannerAndBackdrop();
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  hideBannerAndBackdrop() {
   
    // Hide the banner image
    const bannerImg = document.querySelector('app-gpstore-banner');
    console.log(bannerImg,"bannerImg123");
    
    if (bannerImg) {
      this.renderer.setStyle(bannerImg, 'display', 'none');
    }
 
    // Adjust overlay backdrop styling
    const backdrop = document.querySelector('.cdk-overlay-dark-backdrop');
    if (backdrop) {
      this.renderer.setStyle(backdrop, 'display', 'none');
    }
  }
 
}

<div class="my-5" *ngIf="productList.length > 0">
  <div>
    <div class="header d-flex justify-content-between align-items-center">
      <h5 class="fs-md-5 ">Subscribe Product</h5>
      <h6 class="hover-img" (click)="subscribeList_page()">View all <img src="../../assets/images/arrow.svg" alt="arrow"
          class="ms-2 arrow-img"></h6>
    </div>
    <div class="section mt-4">
      <div id="subscribe-list">
        <div *ngIf="isMobileView; else webSubscribe" class="parent" >
        <div class="parent" #elemt (mousedown)="startDragging($event, false, elemt,'mouse',child, '')"
          (mouseup)="stopDragging($event, false,'mouse')" (mouseleave)="stopDragging($event, false,'mouse')"
          (mousemove)="moveEvent($event, elemt, 'mouse', child)" (scroll)="onScrollOrClick()">
          <div class="child d-flex  position-relative" #child>
            <ng-container *ngTemplateOutlet="subscribeContent"></ng-container>
         
          </div>
        </div>
        </div>
        <ng-template #webSubscribe>
          <div #elemt >
            <div class="child d-flex  position-relative   " #child>
              <ng-container *ngTemplateOutlet="subscribeContent"></ng-container>
            </div>
          </div>
    </ng-template>
 
        <ng-template #subscribeContent>
          <div class="card me-md-2 me-1 col-3" *ngFor="let product of  productList,let i= index">
            <div class="d-flex mobile-position">
              <!-- <div class="offer">{{ product.offer }}</div> -->
              <div *ngFor="let variation of product?.productvariations">
                <div class="offer" *ngIf="variation?.scribecap && variation?.productvariationid === product?.selectedVariation && variation.scribecap !== 'null'">
                  {{variation?.scribecap}}
                </div>
              </div>  
              
              <div class="like-border me-5 d-none d-sm-block">
                <!-- If product is in the wishlist (heart icon) -->
                <span *ngIf="isLoggedIn &&product.wishlistIcon; else noWishListIcon"
                  (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                  <img src="../../assets/images/heart-icon.svg" class="like" alt="heart-icon">
                </span>
 
                <!-- If product is not in the wishlist (border heart icon) -->
                <ng-template #noWishListIcon>
                  <span (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                    <img src="../../assets/images/heart-border-web.png" class="like" alt="like">
                  </span>
                </ng-template>
 
                <img src="../../assets/images/view-border.svg" class="view" alt="view"
                  (click)="openProductDialog(product, product.selectedVariation)">
              </div>
              <!-- for mobile -->
              <div class="mobile-like mt-2  d-block d-lg-none">
                <span *ngIf="isLoggedIn &&product.wishlistIcon; else noWishListIcon"
                  (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                  <img src="../../assets/images/heart-icon.svg" class="heartlike" alt="like">
                </span>
 
                <!-- If product is not in the wishlist (border heart icon) -->
                <ng-template #noWishListIcon>
                  <span (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                    <img src="../../assets/images/heartborder.svg" class="like" alt="like">
                  </span>
                </ng-template>
              </div>
 
            </div>
            <div class="image-container" (click)="productList_page(product.id,product.selectedVariation )">
              <img [src]="product.src?.[0]" class="card-img d-block " alt="product">
            </div>
 
            <div class="card-body px-sm-2">
              <h6 class="card-title mb-md-2 mb-0 text-truncate" (click)="productList_page(product.id,product.selectedVariation )">{{ product.title }}</h6>
              <div class="size-selection d-flex align-items-center">
                <div class="sizes d-flex align-items-center">
                  <button *ngFor="let variation of product.productvariations" (click)="selectSize(product.id, variation)"
                    class="btn size-btn me-1 d-flex align-items-center"
                    [ngClass]="{'selected-btn': variation.productvariationid === product.selectedVariation}"> {{ variation.variationvalue
                    }}{{ variation.variationshortname }}
                  </button>
                </div>
              </div>
              <div class="text-center d-md-flex justify-content-md-between align-items-center mt-1">
                <div class="mt-2">
                  <!-- Display updated price and MRP price -->
                  <p class="amount m-0">{{ product.price | currency:'GBP' }}</p>
                </div>
                <div class="add-btn text-center" (click)="SubcribeClick(product.id, product.selectedVariation)">
                  <a class="btn mt-1"><img src="../../assets/images/cart.svg" alt="cart" class="me-2">Subscribe</a>
                </div>
              </div>
            </div>
          </div>
          </ng-template>
      </div>
      <div class="d-lg-block d-none" *ngIf="productListCount > 4">
        <div class="see-more " (click)="onScrollOrClick()" *ngIf="downArrow">
          <img src="../../assets/images/carousel.svg" class="d-block mx-auto seemoreIcon" alt="downArrow" />
        </div>
        <div class="see-more " (click)="onRightScrollOrClick()" *ngIf="rightArrow">
          <img src="../../assets/images/carousel.svg" class="d-block mx-auto seemoreIcon" alt="downArrow"
            id="right-subscribe" />
        </div>
 
        <div class="see-less " (click)="onLeftScrollOrClick()" *ngIf="upArrow">
          <img src="../../assets/images/arrow-left.svg" class="d-block mx-auto seemoreIcon" alt="upArrow"
            id="left-subscribe" />
        </div>
      </div>
    </div>
 
 
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { loadStripe } from '@stripe/stripe-js';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'primeng/api';
declare const google: any;

@Component({
  selector: 'app-add-cart',
  templateUrl: './add-cart.component.html',
  styleUrls: ['./add-cart.component.css']
})
export class AddCartComponent {
  cartId:any
  deliverytimeslotsmessage:any;
  cashOnDeliveryVisible: boolean = false;
  cartPage = true;
  productPage = true;
  orderPlaced = false;
  deliverAddress = false;
  deliverySchedule = false;
  placeOrderList = false;
  paymentProcess = false;
  orderEmpty = false;
  selectedDay: any;
  orderId: any;
  selectedSchedule: string = ''; // Holds the selected schedule
  WalletBalanceAmount: number = 0;
  selectedAddressId: number | string | null = null;
  paymentActive: boolean = true;
  isNextButtonDisabled: boolean = true;
  selectedPaymentMethod: string[] = [];
  cartItems: any[] = [];
  variations: any[] = [];
  selectedItems: number[] = [];
  comboid : number[] = [];
  count: number | null = null;
  isFirstStep: boolean = true;
  user_details: any = {};
  UserAddressList: any[] = [];
  DeliveryTimesLotsList: any[] = [];
  selectedSlots: any[] = [];
  selectedSlotId: string | null = null;
  deliveryInstruction: string = '';
  isPlaceOrderEnabled: boolean = false;
  currentStep: number = 0;
  tittle: string = 'cart';
  accessToken: string | null = null;
  orderDetails: any = null;
  userId: string = '';
  quantity!: number;
  selectedVariationId: string | null = null;
  private variationsCache = new Map<string, any[]>();
  dataFromPreviousComponent: string | undefined;
  SubcribeProductVariationId: string | undefined;
  SubcribeProductId: string | undefined;
  issubscribeProduct: boolean = false;
  issubscribePage: boolean = false;
  isSubscriptionOrder: boolean = false;
  isCardDisabled: boolean = false;
  private variationsCacheSubject = new BehaviorSubject<Map<string, any[]>>(this.variationsCache);

  // start
  map!: google.maps.Map;
  SearchCountryField = SearchCountryField;
  isMapVisible: boolean = true;
  showCardAddressContent = false;
  showNewAddress = false;
  isEditMode: boolean = false;
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  phone: any;  // Depending on the format from ngx-intl-tel-input
  selectedCountryISO: CountryISO = CountryISO.India; // Default value
  deliveryArea: string = '';
  address: string = '';
  doorNo: string = '';
  landmark: string = '';
  postalCode: string = '';
  selectedAddressType: string | null = null;
  isEditable: boolean = false;
  latitude: number | undefined | null;
  longitude: number | undefined | null;
  selectedAddress: any = '';
  isLoggedIn: any;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  CountryISO = CountryISO;
  // popupOpenDialog = false;
  startDate: string | null = null;
  endDate: string | null = null;
  minEndDate: string | null = null;
  currentDate: string | undefined;
  // end
  saveCardDetails: boolean = false;
  cardHolderName: string = '';
  selectedSavedCard: any = null;
  userCardsList: any[] = [];
  stripe: any;
  cardElement: any;
  cardErrors: string | null = null;
  @ViewChild('pacInput', { static: false }) pacInput!: ElementRef;
  SubscribeSelectVariation: any;

  constructor(public router: Router,private clipboard: Clipboard,private messageService: MessageService, private route: ActivatedRoute, private http: HttpClient, public auth: AuthService, private cartService: CartService, private cdr: ChangeDetectorRef
  ) {
    if (this.userId === '') {
      this.orderEmpty = true;
      this.cartPage = false;
    }
    this.auth.userInfo.subscribe((data: any) => {
      this.userId = data.id;
      if (this.userId) {
        this.orderEmpty = false;
        this.cartPage = true;
      }
      else {
        this.orderEmpty = true;
        this.cartPage = false;

      }
    });
    const navigation = this.router.getCurrentNavigation();
    const dataToPass = navigation?.extras?.state?.['dataToPass'];
    if (dataToPass) {
      this.selectedSchedule = dataToPass.schedule?.toUpperCase() || ''; // Initialize the schedule
      this.startDate = dataToPass.startDate || '';
      this.endDate = dataToPass.endDate || '';
    }
    // Check if dataToPass exists and access its properties safely
    if (dataToPass) {
      this.SubcribeProductId = dataToPass.product_id
      this.SubcribeProductVariationId = dataToPass.variation_id
      this.dataFromPreviousComponent = dataToPass.datafrom;

      if (this.dataFromPreviousComponent === 'SubcribeProduct') {
        this.issubscribeProduct = true;
        this.cashOnDeliveryVisible = true;
        this.orderEmpty = false;
        this.cartList(this.SubcribeProductId);

      } else {
        this.issubscribeProduct = false;
        this.cashOnDeliveryVisible = false;
      }
    } else if (this.userId) {
      this.SubcribeProductId = ''
      this.SubcribeProductVariationId = ''
      this.dataFromPreviousComponent = '';
      this.cartList();

    }

  }

  onSavedCardSelect(card: any) {
    this.selectedSavedCard = card;
    this.isPlaceOrderEnabled = true;
    this.isCardDisabled = true;
  }
  async stripePayment() {
    this.stripe = await loadStripe(environment.STRIPE_PUBLISHABLE_KEY);

    const elements = this.stripe.elements();

    // Create card element with ZIP code disabled
    this.cardElement = elements.create('card', {
      hidePostalCode: true  // Disable ZIP code
    });

    this.cardElement.mount('#card-element');

    this.cardElement.on('change', (event: any) => {
      this.cardErrors = event.error ? event.error.message : null;
      if (this.cardErrors === null) {  // Check if cardErrors is null
        this.isPlaceOrderEnabled = true;
      } else {
        this.isPlaceOrderEnabled = false;  // Disable if there's an error
      }
    });
  }


  async handlePayment(amount: number) {
    if (this.selectedSavedCard) {


      this.createPaymentIntent(this.selectedSavedCard.paymentmethodid, amount ?? 0);
      return;
    }
    const { error, paymentMethod } = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardElement,

    });

    if (error) {
      console.error(error);
      return;
    }
    if (this.saveCardDetails) {
      this.SaveCard(paymentMethod.id, paymentMethod.card);
      this.createPaymentIntent(paymentMethod.id, amount);

    } else {
      this.createPaymentIntent(paymentMethod.id, amount);

    }
  }

  createPaymentIntent(paymentMethodId: string, amount: number): void {

    if (this.dataFromPreviousComponent === 'SubcribeProduct') {
      const startDateTimestamp = this.startDate ? new Date(this.startDate).getTime() : null;
      const endDateTimestamp = this.endDate ? new Date(this.endDate).getTime() : null;

      const requestBody: any = {
        createdBy: this.userId,
        userId: this.userId,
        productId: this.SubcribeProductId,
        productVariationId: this.SubcribeProductVariationId,
        quantity: this.quantity,
        userAddressId: this.selectedAddressId,
        deliverySchedule: this.selectedSchedule,
        deliveryStartDate: startDateTimestamp,
        deliveryEndDate: endDateTimestamp,
        paymentMethods: this.selectedPaymentMethod,
        paymentMethodId: paymentMethodId,
        returnUrl: "https://gpstore.amicodevelopment.net/home",
       
      };
    
      if (this.selectedPaymentMethod.includes("link")) {
        requestBody.redirectStripeUrl= "stripe-order-success"
      }
  
      if (this.deliveryInstruction) {
        requestBody.deliveryInstruction = this.deliveryInstruction;
      }

      this.http.post(environment.apiUrl + '/subscriptions/create', requestBody, { responseType: 'json' })
      .subscribe({
        next: (response: any) => {
          if (response.status === 'SUCCESS') {
            this.isSubscriptionOrder = true; // Subscription order
            this.orderPlaced = true;
            this.cartPage = false;
            this.orderDetails = response.subscriptions;
          } else {
            console.error('Failed to create subscription', response);
          }
        },
        error: (error) => {
          console.error('Error creating subscription:', error);
        }
      });
    

    } else {

      const dayDate = this.selectedDay.day_date; // e.g., "Oct 18"
      const currentYear = new Date().getFullYear(); // Get the current year
      const fullDateString = `${dayDate} ${currentYear}`; // Combine with year, e.g., "Oct 18 2024"

      // Create a new Date object and get the timestamp (milliseconds since 1970)
      const timestamp = new Date(fullDateString).getTime();
      const requestBody: any = {

        createdBy: this.userId,
        userId: this.userId,
        userAddressId: this.selectedAddressId,
        deliveryScheduleDate: timestamp,
        deliveryTimeSlotId: this.selectedSlotId,
        paymentMethods: this.selectedPaymentMethod,
        paymentMethodId: paymentMethodId,
        returnUrl: "https://gpstore.amicodevelopment.net/home",
       
      };

      if (this.selectedPaymentMethod.includes("link")) {
        requestBody.redirectStripeUrl= "stripe-order-success"
      }
  
      if (this.selectedItems && this.selectedItems.length > 0) {
        const validCartitemIds = this.selectedItems.filter(id => id !== null);
        if (validCartitemIds.length > 0) {
          requestBody.cartitemIds = validCartitemIds;
        }
      }
   
      // Include comboid only if it's valid
      if (this.comboid  && this.comboid.length > 0) {
        const validComboIds = this.comboid.filter(id => id !== null);
        if (validComboIds.length > 0) {
          requestBody.comboIds = validComboIds;
        }
      }
      if (this.deliveryInstruction) {
        requestBody.deliveryInstruction = this.deliveryInstruction;
      }

      this.http.post(environment.apiUrl + '/orders/create', requestBody, { responseType: 'json' })
      .subscribe({
        next: (response: any) => {
          this.orderPlaced = true;
          this.cartPage = false;
          this.orderDetails = response.orders[0];
    
          const clientSecret = response.paymentIntent?.client_secret;
          const confirmationMethod = response.paymentIntent?.confirmation_method;
    
          if (clientSecret && confirmationMethod !== 'automatic') {
            // If confirmation_method is not 'automatic', confirm payment manually
            this.stripe.confirmCardPayment(clientSecret)
              .then((result: any) => {
                if (result.error) {
                  console.error('Payment Error:', result.error.message, result.error);
                } else if (result.paymentIntent.status === 'succeeded') {
                  alert('Payment successful!');
                } else {
                  console.error('Payment failed:', result.paymentIntent.status);
                }
              })
              .catch((error: any) => {
                console.error('Error during payment confirmation:', error);
              });
          } else if (confirmationMethod === 'automatic' && response.paymentIntent?.status === 'succeeded') {
            // Payment is automatically confirmed and succeeded
            alert('Payment successful!');
          } else {
            console.error('Failed to initiate payment or payment is already confirmed', response);
          }
        },
        error: (error: any) => {
          console.error('Error initiating payment:', error);
        }
      });
    
    }





  }


  SaveCard(paymentMethodId: any, paymentMethodCard: any): void {
    const requestBody = {
      createdBy: this.userId,
      userId: this.userId,
      cardHolderName: this.cardHolderName,
      cardNumber: paymentMethodCard.last4,
      expMonth: paymentMethodCard.exp_month,
      expYear: paymentMethodCard.exp_year,
      paymentMethodId: paymentMethodId,
      brand: paymentMethodCard.brand
    };

    this.http.post(environment.apiUrl + '/usercarddetails/create', requestBody, { responseType: 'json' })
    .subscribe({
      next: (response: any) => {
        if (response.status === 'SUCCESS') {
          // Handle success logic here
        } else {
          console.error('Failed to create card', response);
        }
      },
      error: (error: any) => {
        console.error('Error creating card:', error);
      }
    });
  
  }



  // start
  // tooggleOpenDialog(){
  //   this.popupOpenDialog = true;

  //   this.initMap();
  // }
  UserEditAddress(id: any): void {
    this.isEditMode = true;
    // Make the GET request to retrieve the address by id
    this.http.get(environment.apiUrl + `/useraddress/get?id=${id}`, { responseType: 'json' })
  .subscribe({
    next: (response: any) => {
      if (response.status === 'SUCCESS') {
        // Assuming the response contains the address data
        const addressData = response.useraddress;

        // Set the component variables to the retrieved address values
        this.firstName = addressData.firstname;
        this.lastName = addressData.lastname;
        this.phone = addressData.phone;
        this.selectedCountryISO = CountryISO.India;
        this.email = addressData.email;
        this.deliveryArea = addressData.deliveryarea;
        this.address = addressData.address;
        this.doorNo = addressData.doorno;
        this.landmark = addressData.landmark;
        this.postalCode = addressData.postalcode;
        this.latitude = addressData.latitude;
        this.longitude = addressData.longitude;
        this.selectedAddressType = addressData.type;
        this.showNewAddress = true;
        this.showCardAddressContent = false;
      } else {
        console.error('Failed to retrieve address:', response);
      }
    },
    error: (error: any) => {
      console.error('Error retrieving address:', error);
    }
  });

  }
  saveAddress(): void {
    this.isFirstStep = true
    if (this.isEditMode) {

      this.updateUserAddress();
    } else {
      this.createUserAddress();

      this.isMapVisible = true;
      this.resetToFirstStep();
      const addAddressModal = document.getElementById('addAddressModal') as HTMLElement;
      if (addAddressModal) {
        addAddressModal.addEventListener('shown.bs.modal', () => {
          this.initMap(); // Call initMap only when the modal is fully shown
        });
      }


    }

  }
  handleModalClose() {
    this.isFirstStep = true;

    // if(  this.isFirstStep &&   this.isMapVisible){
    this.resetToFirstStep();
    // }

  }

  scrollToDiv(divId: string): void {
    const element = document.getElementById(divId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  resetAddressForm(): void {
    this.firstName = '';
    this.lastName = '';
    this.phone = '';
    this.email = '';
    this.deliveryArea = '';
    this.address = '';
    this.doorNo = '';
    this.landmark = '';
    this.postalCode = '';
    this.selectedAddressType = ''; // or 'home', 'office', etc. if you have a default
    this.latitude = null;
    this.longitude = null;
  }
  isFormValid(): boolean {
    return this.firstName && this.selectedAddress && this.address && this.postalCode && this.selectedAddressType;
    // return this.firstName && this.lastName && this.phone && this.email && this.selectedAddress && this.address && this.postalCode && this.selectedAddressType;
  }
  isSelectScheduleFormValid(): boolean {
    return (
      this.selectedSchedule !== '' &&
      this.startDate !== null &&
      this.endDate !== null
      
    );
  }

  enableEdit() {
    this.isEditable = true; // Allow editing when 'Change' is clicked
  }

  ngOnInit() {


    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.initMap();
    }, 0);

    this.isMapVisible = true;
    this.currentDate = new Date().toISOString().split('T')[0];
    
  }


  //  wallet
  UserWallet(): void {
    this.http.get(environment.apiUrl + `/userwallet/get-available-balance?userId=${this.userId}`, { responseType: 'json' })
    .subscribe({
      next: (response: any) => {
        if (response.status === 'SUCCESS') {
          this.WalletBalanceAmount = parseFloat(response.availableBalance) || 0;
        } else {
          console.error('Failed to retrieve wallet:', response);
        }
      },
      error: (error: any) => {
        console.error('Error retrieving wallet:', error);
      }
    });
  
  }


  GoBack() {

    if (this.showNewAddress) {
      // If the address form is open, go back to the map
      this.showNewAddress = false; // Hide the new address form
      this.isMapVisible = true;
      this.initMap();
    } else if (this.isMapVisible) {
      // If the map is visible, close it and hide the address card
      this.showCardAddressContent = false;
      this.isMapVisible = false;
      this.isEditMode = false;     // Reset edit mode
      this.UserAddressListApi();   // Reload or refresh the address list if needed
    }
  }
  createUserAddress(): void {
    const requestBody = {
      id: this.selectedAddressId,  // Or you can dynamically generate an ID if necessary
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone?.e164Number, // Assuming this is a method to get the phone number
      deliveryArea: this.selectedAddress,
      address: this.address,
      doorNo: this.doorNo,
      landmark: this.landmark,
      postalCode: this.postalCode,
      latitude: this.latitude,
      longitude: this.longitude,
      createdBy: this.userId,
      userId: this.userId,
      type: this.selectedAddressType,
    };

    this.http.post(environment.apiUrl + '/useraddress/create', requestBody, { responseType: 'json' })
    .subscribe({
      next: (response: any) => {
        if (response.status === 'SUCCESS') {
          this.showNewAddress = false;
          this.GoBack();
          // Handle success (e.g., show a success message, navigate, or update the UI)
        } else {
          console.error('Failed to create address', response);
        }
      },
      error: (error: any) => {
        console.error('Error creating address:', error);
      }
    });
  
    this.isMapVisible = true;
  }
  updateUserAddress(): void {
    const requestBody = {
      id: this.selectedAddressId,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone?.e164Number,
      deliveryArea: this.deliveryArea,
      address: this.address,
      doorNo: this.doorNo,
      landmark: this.landmark,
      postalCode: this.postalCode,
      latitude: this.latitude,
      longitude: this.longitude,
      updatedBy: this.userId,
      type: this.selectedAddressType,
    };

    // Send HTTP PUT request to update the address
    this.http.post(environment.apiUrl + '/useraddress/update', requestBody, { responseType: 'json' })
    .subscribe({
      next: (response: any) => {
        if (response.status === 'SUCCESS') {
          this.showNewAddress = false;
          this.GoBack();
          // Handle success, such as showing a success message or updating UI
        } else {
          console.error('Failed to update address', response);
        }
      },
      error: (error: any) => {
        console.error('Error updating address:', error);
      }
    });
  
    this.isMapVisible = true;
  }
  toggleCardAddressContent() {
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
    }
    this.selectedAddress = '';
    this.showCardAddressContent = true // Toggle showCardAddressContent
    if (this.showCardAddressContent) {
      this.isMapVisible = true; // Show map only if the card content is visible
      this.showNewAddress = false;
      this.resetAddressForm();
      this.initMap();
    }
    else {
      this.isMapVisible = false; // Hide map if card content is hidden
    }
  }

  initMap() {
    this.isFirstStep = true;
    this.isMapVisible = true

    const mapElement = document.getElementById("map") as HTMLElement;
    if (!mapElement) {

      // console.error("Map container not found.");
      return;
    }
    else {
      const input = document.getElementById("pac-input") as HTMLInputElement;
      const searchBox = new google.maps.places.SearchBox(input);
      if (!input) {
        // console.error("Search input not found. Aborting map initialization.");
        return;  // Exit early if the input is not available
      }

      const defaultLat = -33.8688;
      const defaultLng = 151.2195;

      // Use the selected latitude and longitude if available, else default coordinates
      const centerLat = this.latitude || defaultLat;
      const centerLng = this.longitude || defaultLng;

      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          center: { lat: centerLat, lng: centerLng },
          zoom: 13,
          mapTypeId: "roadmap",
        }
      );

      // Add a marker at the center of the map
      let centerMarker = new google.maps.Marker({
        position: { lat: centerLat, lng: centerLng },
        map: map,
        title: "Selected Location", // Optional: You can set a title for the marker
      });

      // Create the search box and link it to the UI element.
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      // Bias the SearchBox results towards current map's viewport.
      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
      });

      let markers: google.maps.Marker[] = [];

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener("places_changed", () => {
        const places: google.maps.places.PlaceResult[] | undefined = searchBox.getPlaces();

        if (!places || places.length === 0) {
          console.error("No places found");
          return;
        }

        // Remove the center marker if a new place is selected
        if (centerMarker) {
          centerMarker.setMap(null); // Hide the center marker
        }

        // Clear out the old markers.
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];

        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();

        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            return;
          }

          if (place.formatted_address) {
            this.selectedAddress = place.formatted_address; // Set the selected address
            this.cdr.detectChanges();

            // Initialize the geocoder
            const geocoder = new google.maps.Geocoder();

            // Geocode the address
            geocoder.geocode({ address: this.selectedAddress }, (results: {
              geometry: {
                location: {
                  lat(): number | null | undefined; lng: () => number | null | undefined;
                };
              };
            }[], status: string) => {
              if (status === google.maps.GeocoderStatus.OK) {
                // Get latitude and longitude from the result
                this.latitude = results[0].geometry.location.lat();
                this.longitude = results[0].geometry.location.lng();
              } else {
                console.error('Geocoding failed: ' + status);
              }
            });
          }

          const icon = {
            url: place.icon as string,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
          };

          // Create a marker for the selected place.
          markers.push(
            new google.maps.Marker({
              map,
              icon,
              title: place.name,
              position: place.geometry.location,
            })
          );

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });

        map.fitBounds(bounds);
      });
    }
  }

  setAddressType(type: string): void {
    this.selectedAddressType = type;
  }
  showNewAddressContainer() {
    this.showNewAddress = true; // Show the "Add New Address" form
    this.isMapVisible = false;  // Hide the map
  }

  // end
  toggleCardDetails(): void {
    this.deliverAddress = false;
    this.currentStep = 0;
    if (this.dataFromPreviousComponent === 'SubcribeProduct') {
      this.issubscribeProduct = false;
      this.issubscribePage = true
    } else {
      this.productPage = true;
    }
  }
  toggleCardSubcribeDelivery(): void {
    this.deliverAddress = false;
    this.productPage = true;
    this.currentStep = 0;
    if (this.dataFromPreviousComponent === 'SubcribeProduct') {
      this.issubscribeProduct = true;
      this.issubscribePage = false
    }
  }
  toggleCardsubscriptiondelivery() {
    this.deliverAddress = false;
    this.productPage = true;
    this.currentStep = 0;
    if (this.dataFromPreviousComponent === 'SubcribeProduct') {
      this.issubscribeProduct = true;
      this.issubscribePage = false
    }
  }
  goToNextStep() {
    this.isFirstStep = false;
  }
  resetToFirstStep() {

    this.isFirstStep = true;

    this.showCardAddressContent = true // Toggle showCardAddressContent
    // this.isMapVisible = true;

    this.selectedAddress = '';
    if (!this.showCardAddressContent && this.isMapVisible) {
      this.isMapVisible = true; // Show map only if the card content is visible
      this.showNewAddress = false;
      this.selectedAddress = '';
      this.cdr.detectChanges();

      this.resetAddressForm();

      this.initMap();
    }

  }

  // resetToFirstStep() {
  //   this.isFirstStep = true;
  //   this.selectedAddress = '';
  //   this.showCardAddressContent = true // Toggle showCardAddressContent

  //   if (this.showCardAddressContent) {
  //     this.isMapVisible = true; // Show map only if the card content is visible
  //     this.showNewAddress = false;
  //     this.resetAddressForm();
  //     setTimeout(() => {

  //       this.initMap();
  //     }, 0);
  //   }
  //   else {
  //     this.isMapVisible = false; // Hide map if card content is hidden
  //   }
  // }
  toggleScheduleDetails(): void {
    this.deliverAddress = true;
    this.deliverySchedule = false;
    this.currentStep = 1;

  }

  togglePaymentDetails(): void {
    if (this.dataFromPreviousComponent === 'SubcribeProduct') {
      this.deliverAddress = true;
    } else {
      this.deliverySchedule = true;
    }
    this.placeOrderList=false
    this.paymentProcess = false;
    this.currentStep = 2;
    this.selectedPaymentMethod = []
    this.isCardDisabled = false;
  }

  // validation for name
  validateInput(event: KeyboardEvent): void {
    const pattern = /[A-Za-z]/; // Allow only alphabets
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault(); // Prevent input if it's not an alphabet
    }
  }

  // 1st page next btn
  
  async productPage_next() {
    try {
      let isAvailable = true;
  
      // Check delivery times if not subscribing to a product
      if (this.dataFromPreviousComponent !== 'SubcribeProduct') {
        isAvailable = await this.checkDeliveryTimesLots();
      }
  
      // Stop navigation if delivery slots are unavailable
      if (!isAvailable && this.dataFromPreviousComponent !== 'SubcribeProduct') {
        console.warn("Delivery slots are unavailable. Navigation halted.");
        return;
      }
  
      // Scroll to top
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
  
      // Navigation logic
      if (this.issubscribeProduct) {
        this.issubscribePage = true;
        this.issubscribeProduct = false;
      } else {
        this.currentStep = 1;
        this.deliverAddress = true;
        await this.UserAddressListApi();
      }
  
      this.productPage = false;
      this.placeOrderList = false;
    } catch (error) {
      console.error("Error navigating to the next page:", error);
    }
  }
  

  // 2nd page subcribeDeliverySchedule
  subscriptionDeliverySchedule() {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    })
    this.issubscribePage = false
    this.currentStep = 1;
    this.deliverAddress = true;
    this.UserAddressListApi()
  }
  // 2nd page next btn
  deliverAddress_next() {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    })

    if (this.dataFromPreviousComponent === 'SubcribeProduct') {
      this.paymentProcess = true;
      this.placeOrderList = true;
      this.UserWallet();
    } else {
      this.deliverySchedule = true
    this.placeOrderList = false;

    this.DeliveryTimesLots()

    }
    this.currentStep = 2;
    this.deliverAddress = false;
  }
  // 3rd page next btn
  deliverySchedule_next() {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    })

    this.currentStep = 3;
    this.deliverySchedule = false;
    this.paymentProcess = true;
    if (this.paymentProcess) {
      this.UserWallet()

    }
    this.placeOrderList = true;

  }

  // 4th page next btn
  toggleSelection(paymentMethod: string) {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    })

    if (paymentMethod === 'wallet') {
      if (this.selectedPaymentMethod.includes('wallet')) {
        // Unselect wallet if it's already selected
        const index = this.selectedPaymentMethod.indexOf('wallet');
        this.selectedPaymentMethod.splice(index, 1);
      } else {
        // Add wallet if it's not already selected
        this.selectedPaymentMethod.push('wallet');

      }
    } else {
      this.UserCardList()
      if(paymentMethod =='card'){
        setTimeout(() => {
          this.stripePayment();
        }, 0);
      }
     

      // For other payment methods, allow only one to be selected at a time
      if (this.selectedPaymentMethod.includes(paymentMethod)) {
        // Unselect the current payment method if it's already selected
        const index = this.selectedPaymentMethod.indexOf(paymentMethod);
        
        this.stripePayment()
        this.selectedPaymentMethod.splice(index, 1);
      } else {

        // Clear any existing non-wallet selections (radio button behavior)
        this.selectedPaymentMethod = this.selectedPaymentMethod.filter(method => method === 'wallet');
        // Add the selected payment method
        this.selectedPaymentMethod.push(paymentMethod);
      }
    }
    const walletBalanceCoversTotal = this.getTotalPriceAfterWalletDeduction() === 0;
    // Enable "Place Order" if wallet balance fully covers the total or a non-wallet method is selected
    if (this.selectedPaymentMethod.includes('wallet') && walletBalanceCoversTotal) {
      this.isPlaceOrderEnabled = true;  // Enable if wallet covers the total

    } else if (this.selectedPaymentMethod.some(method => method !== 'wallet')) {
      if (this.selectedPaymentMethod.some(method => method == 'card')) {
        this.isPlaceOrderEnabled = false;

      } else {
        this.isPlaceOrderEnabled = true;

      }

    } else {
      this.isPlaceOrderEnabled = false; // Disable otherwise

    }

    // Enable the "Place Order" button if any payment method is selected
    // this.isPlaceOrderEnabled = this.selectedPaymentMethod.length > 0;
  }

  UserCardList() {
    const requestBody = {
      userId: this.userId,
    }
    this.http.post(environment.apiUrl + `/usercarddetails/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.userCardsList = data.usercarddetails_output;

        }
      })
  }

  placeOrder() {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    })

    this.currentStep = 4;

    if (this.isPlaceOrderEnabled) {
      // Check if 'card' is selected (with or without 'wallet')
      if (this.selectedPaymentMethod.includes('card')) {
        this.handlePayment(this.getTotalPriceAfterWalletDeduction());
      }
      if (this.selectedPaymentMethod.includes('link')) {
        if (this.dataFromPreviousComponent === 'SubcribeProduct') {
          this.SubcribecreateOrder()
        } else {
          this.createOrder();
        }
      }
      // Check if 'cash on delivery' is selected (with or without 'wallet')
      else if (this.selectedPaymentMethod.includes('cash on delivery')) {

        if (this.dataFromPreviousComponent === 'SubcribeProduct') {
          this.SubcribecreateOrder()
        } else {
          this.createOrder();
        }
      }
      // Check if only 'wallet' is selected
      else if (this.selectedPaymentMethod.length === 1 && this.selectedPaymentMethod.includes('wallet')) {
        if (this.dataFromPreviousComponent === 'SubcribeProduct') {
          this.SubcribecreateOrder()
        } else {
          this.createOrder();
        }
      }
    }
  }
  navigateToProfile(section: string, event: MouseEvent, id: any) {

    event.stopPropagation();
    this.router.navigate(['/profile'], { queryParams: { section, id } });
  }

  // getOrder(id: any): void {
  //   this.http.get(`${environment.apiUrl}/orders/get?id=${id}&userId=${this.userId}`, { responseType: 'json' })
  //   .subscribe({
  //     next: (data: any) => {
  //       if (data.status === 'SUCCESS') {
  //         this.orderDetails = data.orders;
  //         // Fetch images for each cart item
  //         this.orderDetails.cartitems.forEach((item: any) => {
  //           item.src = []; // Initialize src array for each item
  
  //           // Fetch image data
  //           this.http.get(`${environment.apiUrl}/uploads/list-by-datafor?dataFor=products&dataForId=${item.productid}`, { responseType: 'json' })
  //             .subscribe({
  //               next: (datas: any) => {
  //                 if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
  //                   this.http.get(`${environment.apiUrl}/uploads/get?id=${datas.uploads[0].id}`, { responseType: 'json' })
  //                     .subscribe({
  //                       next: (res: any) => {
  //                         if (res.status === "SUCCESS") {
  //                           // Push the base64 image data to the item
  //                           item.src.push(res.uploads.base64data);
  //                         }
  //                       },
  //                       error: (error: any) => {
  //                         console.error("Error fetching image:", error);
  //                       }
  //                     });
  //                 }
  //               },
  //               error: (error: any) => {
  //                 console.error("Error fetching uploads:", error);
  //               }
  //             });
  //         });
  //       }
  //     },
  //     error: (error: any) => {
  //       console.error("Error fetching order details:", error);
  //     }
  //   });
  
  // }




  onStartDateChange() {
    if (this.startDate) {
      // Set the minimum end date to the selected start date
      this.minEndDate = this.startDate;
      // Clear the end date if it is before the new start date
      if (this.endDate && this.endDate < this.startDate) {
        this.endDate = null;
      }
      this.calculateDeliveryDays();
    }
  }
  onEndDateChange() {
    this.calculateDeliveryDays();
  }
  deliveryDates: string[] = []; // List of calculated delivery dates
  deliveryPrice: any
  mrpPriceSubscribe: any
  calculateDeliveryDays() {

    if (this.startDate && this.endDate && this.selectedSchedule) {
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
      const schedule = this.selectedSchedule.toUpperCase();
      const dates: string[] = [];
      let deliveryPrice = 0;

      let current = new Date(start);
      while (current <= end) {
        dates.push(current.toISOString().split('T')[0]); // Add current date in 'YYYY-MM-DD' format

        // Increment based on schedule
        switch (schedule) {
          case 'DAILY':
            current.setDate(current.getDate() + 1);
            break;
          case 'ALTERNATE':
            current.setDate(current.getDate() + 2);
            break;
          case 'EVERY_3_DAYS':
            current.setDate(current.getDate() + 3);
            break;
          case 'WEEKLY':
            current.setDate(current.getDate() + 7);
            break;
          case 'MONTHLY':
            current.setMonth(current.getMonth() + 1);
            break;
        }
      }

      // Calculate the total days between start and end dates
      const totalDays = Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24));

      // Determine price based on total days
      if (totalDays <= 7) {
        deliveryPrice = +this.SubscribeSelectVariation.priceupto7days;
      } else if (totalDays <= 30) {
        deliveryPrice = +this.SubscribeSelectVariation.priceupto30days;
      } else {
        deliveryPrice = +this.SubscribeSelectVariation.pricemorethan30days;
      }

      // Set deliveryDates and deliveryPrice to class properties
      this.deliveryDates = dates;
      this.deliveryPrice = deliveryPrice;
      this.mrpPriceSubscribe = this.SubscribeSelectVariation.mrpprice

      // Call getDeliveryPrice for delivery price calculation
      this.getMrpSubscribePrice();
    }
  }




  createOrder() {
    const dayDate = this.selectedDay.day_date;
    const currentYear = new Date().getFullYear();
    const fullDateString = `${dayDate} ${currentYear}`;
    const timestamp = new Date(fullDateString).getTime();

    const requestBody: any = {
      createdBy: this.userId,
      userId: this.userId,
      userAddressId: this.selectedAddressId,
      deliveryScheduleDate: timestamp,
      deliveryTimeSlotId: this.selectedSlotId,
      paymentMethods: this.selectedPaymentMethod,
    };
    if (this.selectedPaymentMethod.includes("link")) {
      requestBody.redirectStripeUrl= "stripe-order-success"
    }

    if (this.selectedItems && this.selectedItems.length > 0) {
      const validCartitemIds = this.selectedItems.filter(id => id !== null);
      if (validCartitemIds.length > 0) {
        requestBody.cartitemIds = validCartitemIds;
      }
    }
 
    // Include comboid only if it's valid
    if (this.comboid  && this.comboid.length > 0) {
      const validComboIds = this.comboid.filter(id => id !== null);
      if (validComboIds.length > 0) {
        requestBody.comboIds = validComboIds;
      }
    }

    if (this.deliveryInstruction) {
      requestBody.deliveryInstruction = this.deliveryInstruction;
    }

    this.http.post(`${environment.apiUrl}/orders/create`, requestBody, { responseType: 'json' })
    .subscribe({
      next: (response: any) => {
        if (response.status === 'SUCCESS') {
          this.isSubscriptionOrder = false; // Regular order
          this.orderPlaced = true;
          this.cartPage = false;
          this.orderDetails = response.orders[0];
  
          this.cartService.updateCartCount(this.userId);
          if (response.session && response.session.url) {
            // Copy the URL to the clipboard
            this.clipboard.copy(response.session.url);
            this.messageService.add({ severity: 'success', summary: 'URL has been copied to clipboard', detail: 'Please share your URL...' ,  life: 3000,  });
          }  else {
            console.error('No URL provided in the response');
          }
        } else {
          console.error('Failed to create order', response);
        }
      },
      error: (error: any) => {
        console.error('Error creating order:', error);
      }
    });
  
  }

  SubcribecreateOrder() {
    const startDateTimestamp = this.startDate ? new Date(this.startDate).getTime() : null;
    const endDateTimestamp = this.endDate ? new Date(this.endDate).getTime() : null;

    const requestBody: any = {
      createdBy: this.userId,
      userId: this.userId,
      productId: this.SubcribeProductId,
      productVariationId: this.SubcribeProductVariationId,
      quantity: this.quantity,
      userAddressId: this.selectedAddressId,
      deliverySchedule: this.selectedSchedule,
      deliveryStartDate: startDateTimestamp,
      deliveryEndDate: endDateTimestamp,
      paymentMethods: this.selectedPaymentMethod,
      returnUrl: "https://gpstore.amicodevelopment.net/home",
     };

 if (this.selectedPaymentMethod.includes("link")) {
      requestBody.redirectStripeUrl= "stripe-order-success"
    }

    if (this.deliveryInstruction) {
      requestBody.deliveryInstruction = this.deliveryInstruction;
    }

    this.http.post(`${environment.apiUrl}/subscriptions/create`, requestBody, { responseType: 'json' })
    .subscribe({
      next: (response: any) => {
        if (response.status === 'SUCCESS') {
          this.isSubscriptionOrder = true; // Subscription order
          this.orderPlaced = true;
          this.cartPage = false;
          this.orderDetails = response.subscriptions;
  
          if (response.session && response.session.url) {
            // Navigate to the redirect page with the URL as a parameter
            this.clipboard.copy(response.session.url);
            this.messageService.add({ severity: 'success', summary: 'URL has been copied to clipboard', detail: 'Please share your URL...' ,  life: 3000,  });
          } else {
            console.error('No URL provided in the response');
          }
        } else {
          console.error('Failed to create subscription', response);
        }
      },
      error: (error: any) => {
        console.error('Error creating subscription:', error);
      }
    });
  
  }

  detectLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
      }, (error) => {
        console.error(error);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  setActiveTittle(tittle: string): void {
    this.tittle = tittle;
  }
  // (change)="onCheckboxChange($event, item)"
  onCheckboxChange(event: any, item: any) {

    if (event.target.checked) {
      this.selectedItems.push(item.cartitemid);
      this.comboid.push(item.comboid);
      this.cartId = item.cartid;
      item.hasChecked = true;
      this.add_to_cart_count()

    } else {
      // Remove the id from the array if checkbox is unchecked
      this.selectedItems = this.selectedItems.filter(itemId => itemId !== item.cartitemid);
      this.comboid = this.comboid.filter(itemId => itemId !== item.comboid);

      item.hasChecked = false;
      this.add_to_cart_count()
    }
    this.checkIfAnyCheckboxChecked();
  }
  add_to_cart_count() {
    return this.cartItems.filter((item: any) => item.hasChecked).length;
  }
  checkIfAnyCheckboxChecked() {
    // If at least one item is selected, enable the "Next" button
    this.isNextButtonDisabled = this.selectedItems.length === 0;
  }
  type:any
// cart-list
  cartList(subcategoryId?: string) {
    const subcategory_id = subcategoryId || '';
    const userIdParam = this.userId ? `&userId=${this.userId}` : '';
    const requestBody = { userId: this.userId };
 
    // Determine the API endpoint based on subscription status
    const apiUrl = this.issubscribeProduct
      ? `${environment.apiUrl}/products/user-view-get?id=${subcategory_id}${userIdParam}&type='is_subscribe'`
      : `${environment.apiUrl}/cart/list`;
 
    const apiCall = this.issubscribeProduct
      ? this.http.get(apiUrl, { responseType: 'json' })
      : this.http.post(apiUrl, requestBody, { responseType: 'json' });
 
      apiCall.subscribe({
        next: (data: any) => {
          // Initialize cart items
          if (data.cartitems_output?.length > 0 || data.products) {

            this.cartItems = this.issubscribeProduct && !Array.isArray(data.products)
              ? [data.products]
              : this.issubscribeProduct
                ? data.products
                : data.cartitems_output;
      
            if (!Array.isArray(this.cartItems)) {
              this.cartItems = [];
            }
      
            // Process each item
            this.cartItems.forEach((item: any) => {
              item.hasChecked = false;
      
              // Determine variations and images
              if (this.issubscribeProduct) {
                this.comboid = item.comboid;
                item.variations = item.productvariations || [];
                const selectedVariation = item.variations.find(
                  (v: any) => v.productvariationid === this.SubcribeProductVariationId
                );
                this.SubscribeSelectVariation = selectedVariation;
                if (selectedVariation) {
                  item.selectedVariationId = selectedVariation.productvariationid;
                  item.selectedPrice = selectedVariation.priceupto7days;
                  item.mrpPrice = selectedVariation.mrpprice;
                  item.selectedVariationValue = selectedVariation.variationvalue;
                  item.selectedVariationShortname = selectedVariation.variationshortname;
                  this.deliveryPrice = selectedVariation.priceupto7days;
                  this.mrpPriceSubscribe = selectedVariation.mrpprice;
                  item.quantity = 1;
                  this.quantity = item.quantity;
                  this.isNextButtonDisabled = false;
      
                  this.getMrpSubscribePrice();
                  this.getSubTotalSubscribePrice();
                }
              } else {
                item.selectedVariation = item;
                if (item.selectedVariation) {
                  item.selectedVariationId = item.id;
                  item.selectedPrice = item.sellingprice;
                  item.mrpPrice = item.mrpprice;
                  item.selectedVariationValue = item.variationvalue;
                  item.selectedVariationShortname = item.variationshortname;
                }
              }
      
              // Fetch image for the item
              let imageId = null;
              if (item.imageinfo) {
                const imageInfoArray = JSON.parse(item.imageinfo);
                if (Array.isArray(imageInfoArray) && imageInfoArray.length > 0) {
                  imageId = imageInfoArray[0].imageid; // Use the first image ID
                }
              }
      
              // Check if `imageId` is available
              if (imageId) {
                const imageApi = `${environment.apiUrl}/uploads/get?id=${imageId}`;
                this.http.get(imageApi).subscribe({
                  next: (res: any) => {
                    item.src = res.status === "SUCCESS" ? [res.uploads.base64data] : [];
                  },
                  error: (error) => {
                    console.error('Error fetching image:', error);
                    item.src = [];
                  }
                });
              } else {
                item.src = [];
              }
            });
      
            this.count = data.count;
            this.orderEmpty = false;
      
            if (!this.issubscribeProduct) {
              this.getMrpPrice();
              this.getSubTotalPrice();
            }
          } else {
            
            this.orderEmpty = true;
            this.cartItems = [];
          }
        },
        error: (error) => {
          console.error("Error in cartList API call:", error);
          this.orderEmpty = true;
        }
      });
      
  }
 
  getSubTotalPrice(): number {
    return this.cartItems.reduce((total, item) => {
      const sellingPrice = parseFloat(item.price ?? '0');  // Use item.selectedPrice directly
      if (item.hasChecked) {
        return total + (sellingPrice * item.quantity);
      } else {
        return total;
      }
    }, 0);
  }
 
  Add_to_cart(  productId: number,
    productVariationId: string,
    quantity: number,
    isCombo: boolean = false,
    comboId: string = '',
    comboProducts: Array<{ productid: string; productvariationid: string }> = []
  ): void {
    // Determine the type based on the comboId presence
    const type = isCombo && comboId ? 'is_combo' : this.issubscribeProduct ? 'is_subscribe' : 'is_sale';
 
    const requestBody: any = {
      userId: this.userId,
      type: type,
      quantity: quantity
    };
 
    if (type === 'is_combo') {
      requestBody.comboId = comboId;
      requestBody.comboProducts = comboProducts;
    } else {
      requestBody.productId = productId;
      requestBody.productVariationId = productVariationId;
    }
 
 
    this.http.post(`${environment.apiUrl}/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          this.cartId = data.cartitems.cartid;
        } else {
          console.error('Failed to add to cart:', data);
        }
      },
      error: (error: any) => {
        console.error('Error in cartList API call:', error);
      }
    });
    
  }
 
 


  enableNewCard() {
    this.isCardDisabled = false;
    this.selectedSavedCard = ''
    this.isPlaceOrderEnabled = false
  }

  ShowMore() {
    this.router.navigate(['/profile'], { queryParams: { section: 'orders' } });
  }

  UserAddressListApi(): void {
    const requestBody = {
      userId: this.userId,
    };
    this.http.post(`${environment.apiUrl}/useraddress/list`, requestBody, { responseType: 'json' }).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          if (Array.isArray(data.useraddress_output)) {
            this.UserAddressList = data.useraddress_output;
    
            // Find the default address and set selectedAddressId
            const defaultAddress = this.UserAddressList.find(address => address.isdefault === true);
            if (defaultAddress) {
              this.selectedAddressId = defaultAddress.id; // Set the default address as selected
            } else if (this.UserAddressList.length > 0) {
              // If no default address is found, optionally select the first address
              this.selectedAddressId = this.UserAddressList[0].id;
            }
          } else {
            console.error("Expected an array for useraddress_output:", data.useraddress_output);
          }
        } else {
          console.error("Failed to fetch user addresses:", data);
        }
      },
      error: (error: any) => {
        console.error("UserAddressList API Error:", error);
      }
    });
    

  }

  DeliveryTimesLots() {
    const requestBody: any = {
      cartId: this.cartId,
    };
    if (this.selectedItems && this.selectedItems.length > 0) {
      const validCartitemIds = this.selectedItems.filter(id => id !== null);
      if (validCartitemIds.length > 0) {
        requestBody.cartitemIds = validCartitemIds;
      }
    }
    if (this.comboid  && this.comboid.length > 0) {
      const validComboIds = this.comboid.filter(id => id !== null);
      if (validComboIds.length > 0) {
        requestBody.comboIds = validComboIds;
      }
      // requestBody.comboIds = this.comboid;
    }
    // Include comboid only if it's valid
    // if (this.comboid) {
    //   requestBody.comboIds = this.comboid;
    // }
    this.http.post(`${environment.apiUrl}/deliverytimeslots/list-next-7days-deliveryslot`, requestBody, { responseType: 'json' }).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          this.DeliveryTimesLotsList = data.deliverytimeslots;
        } else {
          console.error("Failed to fetch delivery slots:", data);
        }
      },
      error: (error: any) => {
        console.error("Error fetching delivery slots:", error);
      }
    });
    
  }
 
  checkDeliveryTimesLots(): Promise<boolean> {
 
    const requestBody: any = {
      cartId: this.cartId,
    };
    if (this.selectedItems && this.selectedItems.length > 0) {
      const validCartitemIds = this.selectedItems.filter(id => id !== null);
      if (validCartitemIds.length > 0) {
        requestBody.cartitemIds = validCartitemIds;
      }
    }
    if (this.comboid  && this.comboid.length > 0) {
      const validComboIds = this.comboid.filter(id => id !== null);
      if (validComboIds.length > 0) {
        requestBody.comboIds = validComboIds;
      }
      // requestBody.comboIds = this.comboid;
    }
    // Include comboid only if it's valid
    // if (this.comboid) {
    //   requestBody.comboIds = this.comboid;
    // }
 
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiUrl}/deliverytimeslots/check-delivery-slot-7days`, requestBody, { responseType: 'json' }).subscribe({
        next: (data: any) => {
          if (data.status === 'SUCCESS') {
            this.deliverytimeslotsmessage = data.message;
      
            if (data.message === "Delivery slots are unavailable") {
              alert(this.deliverytimeslotsmessage);
              resolve(false); // Indicate failure
            } else {
              resolve(true); // Indicate success
            }
          } else {
            alert("An error occurred: " + data.message);
            resolve(false); // Indicate failure
          }
        },
        error: (error: any) => {
          console.error("Error in checkDeliveryTimesLots API call:", error);
          alert("An error occurred while checking delivery slots.");
          reject(false); // Indicate failure
        }
      });
      
    });
  }
 
  selectDay(deliverytimelist: any) {
    this.selectedSlots = deliverytimelist.slots || []; // Get the slots for the selected day
    this.selectedSlotId = ''; // Reset the selected slot ID
    this.selectedDay = deliverytimelist;

  }
 
  getMrpPrice(): number {
    return this.cartItems.reduce((total, item) => {
      const mrpPrice = parseFloat(item.mrp ?? '0');
      if (item.hasChecked) {
        return total + (mrpPrice * item.quantity);
      } else {
        return total;
      }
    }, 0);
  }

 
  getTotalPriceAfterWalletDeduction(): number {
    const subTotalPrice = this.getSubTotalPrice();
    const walletBalance = this.WalletBalanceAmount ?? 0;

    if (this.selectedPaymentMethod.includes('wallet')) {
      // Deduct wallet balance from the subtotal
      return Math.max(subTotalPrice - walletBalance, 0); // Ensure the total doesn't go below 0
    }

    return subTotalPrice; // If wallet is not selected, return subtotal
  }

  getPriceDifference(): number {

    const mrpPrice = this.getMrpPrice();
    const subTotalPrice = this.getSubTotalPrice();

    return mrpPrice - subTotalPrice;

  }

  // subcribe price change:
  getMrpSubscribePrice(): number {

    // Check if deliveryDates is defined and not empty
    if (this.deliveryDates && this.deliveryDates.length > 0) {

      // Calculate total delivery price
      const totalDeliveryPrice = this.mrpPriceSubscribe * this.deliveryDates.length * this.quantity;
      return totalDeliveryPrice;
    }

    // If deliveryDates is not defined or empty, return mrpPriceSubscribe
    return this.mrpPriceSubscribe * this.quantity || 0; // Default to 0 if mrpPriceSubscribe is undefined
  }
  productList_page(productId: string, selectedVariation: any): void {  
    
    // Check for type and other properties in selectedVariation
    const isSubscribeProduct = this.issubscribeProduct;
    const variationId = isSubscribeProduct 
        ? this.SubscribeSelectVariation?.productvariationid 
        : selectedVariation?.productvariationid || null;

    const datafrom = isSubscribeProduct 
        ? "subscribeProduct" 
        : selectedVariation?.type === "is_sale" 
            ? "bestSelling" 
            : "ComboOffer"; // Add a default or fallback type if needed

    
    this.router.navigate(['/product-pages', productId], {
      state: { dataToPass: { datafrom: datafrom, variationId: variationId } }
    });
}

 

  getSubTotalSubscribePrice(): number {

    if (this.deliveryDates && this.deliveryDates.length > 0) {

      const totalDeliveryPrice = this.deliveryPrice * this.deliveryDates.length * this.quantity;
      return totalDeliveryPrice;
    }

    return this.deliveryPrice * this.quantity || 0; // Return 0 if deliveryPrice or deliveryDates is not defined
  }

  getSubscribePriceDifference(): number {

    const mrpPrice = this.getMrpSubscribePrice();
    const subTotalPrice = this.getSubTotalSubscribePrice();

    return mrpPrice - subTotalPrice;

  }
  getTotalSubscribePriceAfterWalletDeduction(): number {
    const subTotalPrice = this.getSubTotalSubscribePrice();
    const walletBalance = this.WalletBalanceAmount ?? 0;

    if (this.selectedPaymentMethod.includes('wallet')) {
      // Deduct wallet balance from the subtotal
      return Math.max(subTotalPrice - walletBalance, 0); // Ensure the total doesn't go below 0
    }

    return subTotalPrice; // If wallet is not selected, return subtotal
  }
  increaseQuantity(item: any): void {

    const selectedVariationId = item.productvariationid || item.selectedVariation?.productvariationid;

    item.quantity = (Number(item.quantity) || 0) + 1;
    this.quantity = item.quantity;

    if (item.comboid) {
      // Handle combo product if comboId is present
      const comboId = item.comboid;
      const comboProducts = item.cartproducts.map((product: any) => ({
        productid: product.productid,
        productvariationid: product.productvariationid
      }));
      this.Add_to_cart(0, '', item.quantity, true, comboId, comboProducts);
    } else {
      // Handle regular or subscription product
      if (this.issubscribeProduct) {
        this.getSubTotalSubscribePrice();
      } else {
        this.getSubTotalPrice();
        this.Add_to_cart(item.productid, selectedVariationId, item.quantity);
      }
    }
 
  }

  decreaseQuantity(item: any): void {
    const selectedVariationId = item.productvariationid || item.selectedVariation?.productvariationid;
    item.quantity = (Number(item.quantity) || 1) - 1;
    if (item.quantity < 1) item.quantity = 1; // Prevent quantity from going below 1
    this.quantity = item.quantity;
 
    if (item.comboid) {
      // Handle combo product if comboId is present
      const comboId = item.comboid;
      const comboProducts = item.cartproducts.map((product: any) => ({
        productid: product.productid,
        productvariationid: product.productvariationid
      }));
      this.Add_to_cart(0, '', item.quantity, true, comboId, comboProducts);
    } else {
      // Handle regular or subscription product
      if (this.issubscribeProduct) {
        this.getSubTotalSubscribePrice();
      } else {
        this.getSubTotalPrice();
        this.Add_to_cart(item.productid, selectedVariationId, item.quantity);
      }
    }
  }  
 
  allow_substitute(
    AllowSubstitute: boolean,
    cartitemIds: string[] = [],
    cartId: string,
    comboIds: string[] = []
  ): void {
    const requestBody: any = {
      allowSubstitute: AllowSubstitute,
      cartId: cartId,
    };
 
    // Include `cartitemIds` and `comboIds` only if they exist
    if (cartitemIds.length > 0) {
      requestBody.cartitemIds = cartitemIds;
    }
    if (comboIds.length > 0) {
      requestBody.comboIds = comboIds;
    }
 
    this.http.post(`${environment.apiUrl}/cart/update-allow-substitute`, requestBody, { responseType: 'json' }).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          // Handle success if needed
          // You can add success handling code here
        }
      },
      error: (error: any) => {
        console.error('An error occurred while updating substitute:', error);
      }
    });
    
  }
 
 
  onSubstituteChange(event: any, item: any): void {
    const isChecked = event.target.checked;
    const cartitemIds = item.cartitemid ? [item.cartitemid] : [];
    const comboIds = item.comboid ? [item.comboid] : [];
    const cartId = item.cartid;
    this.allow_substitute(isChecked, cartitemIds,cartId,comboIds);
  }
 
  onMasterCheckboxChange(event: any): void {
    const isChecked = event.target.checked;
    this.cartItems.forEach(item => {
      item.allowsubstitute = isChecked;
    });
    const regularCartItems = this.cartItems.filter(item => !item.comboid);
    const comboItems = this.cartItems.filter(item => item.comboid);
    // Collect IDs for API call
    const regularCartitemIds = regularCartItems.map(item => item.cartitemid).filter(id => id);
    const comboIds = comboItems.map(item => item.comboid).filter(id => id);
  const cartId = this.cartItems[0]?.cartid || null; // Assuming cartId is consistent across items
 
  // Make API calls for regular items and combos separately
  if (regularCartitemIds.length > 0) {
    this.allow_substitute(isChecked, regularCartitemIds, cartId);
  }
  if (comboIds.length > 0) {
    this.allow_substitute(isChecked, [], cartId, comboIds);
  }
  }
 
// delete cart-items
// delete cart-items
deleteCartItem(cartitemIds: string[] = [], cartId: string, comboIds: string[] = []): void {
  const requestBody: any = {
    updatedBy: this.userId,
    cartId: cartId,
  };
 
  // Include cartitemIds only if it has valid entries
  if (cartitemIds && cartitemIds.length > 0) {
    requestBody.cartitemIds = cartitemIds;
  }
 
  // Include comboIds only if it has valid entries
  if (comboIds && comboIds.length > 0) {
    requestBody.comboIds = comboIds;
  }
 
  this.http.post(`${environment.apiUrl}/cart/delete`, requestBody, { responseType: 'json' }).subscribe({
    next: (data: any) => {
      if (data.status === 'SUCCESS') {
        // Filter out deleted items
        if (cartitemIds && cartitemIds.length > 0) {
          this.cartItems = this.cartItems.filter(item => !cartitemIds.includes(item.cartitemid));
        }
        if (comboIds && comboIds.length > 0) {
          this.cartItems = this.cartItems.filter(item => !comboIds.includes(item.comboid));
        }
        
        // Update the cartItems array and count
        this.cartItems = [...this.cartItems];
        this.count = this.cartItems.length;
        if(  this.count == 0){
        this.orderEmpty=true

        }
        // Update the cart item count in the cart service
        this.cartService.updateCartCount(this.userId);
      }
    },
    error: (error: any) => {
      console.error('An error occurred while deleting cart items:', error);
      // Optionally, you can show a message or take other actions on error
    }
  });
  
}
 
clearAllItems(): void {
  if (this.cartItems.length > 0) {
    // Separate regular and combo items
    const regularCartItems = this.cartItems.filter(item => !item.comboid);
    const comboItems = this.cartItems.filter(item => item.comboid);
 
    // Collect IDs for API call
    const regularCartitemIds = regularCartItems.map(item => item.cartitemid).filter(id => id); // Ensure no null or undefined IDs
    const comboIds = comboItems.map(item => item.comboid).filter(id => id); // Ensure no null or undefined IDs
    const cartId = this.cartItems[0]?.cartid || null; // Assuming cartId is consistent across items
 
 
    // Call deleteCartItem for regular items and combos separately
    if (regularCartitemIds.length > 0) {
      this.deleteCartItem(regularCartitemIds, cartId);
    }
    if (comboIds.length > 0) {
      this.deleteCartItem([], cartId, comboIds);
    }
  }
}
 
}






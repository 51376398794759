<div class="mx-xl-4 px-xl-3 px-2 home-page">
  <!-- carousel -->
  <div class="d-lg-flex gap-lg-3 px-2">
      <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
          <div class="carousel-inner">
              <div class="carousel-item active">
                  <div class="img-wrapper">
                      <img src="../../assets/carousel-image/carousel-1.svg" class="d-block" alt="...">
                  </div>
                  <div class="carousel-caption text-start" *ngFor="let category of mainCategories">
                      <ng-container *ngIf="category.name === 'Grocery'">
                          <h1>GP STORE</h1>
                          <h2>There’s You Can Buy</h2>
                          <h2>all Your Grocery Products</h2>
                          <button class="btn" (click)="groceryOpen(category)">Shop now</button>
                      </ng-container>
                  </div>
              </div>
              <div class="carousel-item">
                  <div class="img-wrapper">
                      <img src="../../assets/carousel-image/carousel-2.svg" class="d-block" alt="...">
                  </div>
                  <div class="carousel-caption text-start" *ngFor="let category of mainCategories">
                      <ng-container *ngIf="category.name === 'Grocery'">
                          <h1>GP STORE</h1>
                          <h2>There’s You Can Buy</h2>
                          <h2>all Your Grocery Products</h2>
                          <button class="btn" (click)="groceryOpen(category)">Shop now</button>
                      </ng-container>
                  </div>
              </div>
              <div class="carousel-item">
                  <div class="img-wrapper">
                      <img src="../../assets/carousel-image/carousel-3.svg" class="d-block" alt="...">
                  </div>
                  <div class="carousel-caption text-start" *ngFor="let category of mainCategories">
                      <ng-container *ngIf="category.name === 'Grocery'">
                          <h1>GP STORE</h1>
                          <h2>There’s You Can Buy</h2>
                          <h2>all Your Grocery Products</h2>
                          <button class="btn" (click)="groceryOpen(category)">Shop now</button>
                      </ng-container>
                  </div>
              </div>
          </div>
          <!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button> -->
          <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="0" class="active"
                  aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="1"
                  aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="2"
                  aria-label="Slide 3"></button>
          </div>

      </div>
      <div class="hot-food d-block">
          <img src="../../assets/carousel-image/carousel-4.svg" alt="" class="d-none d-lg-block">
          <img src="../../assets/carousel-image/hot-food-mobile.svg" alt="" class="d-lg-none d-block">

          <div class="hot-food-captions text-start px-3" *ngFor="let category of mainCategories">
              <ng-container *ngIf="category.name === 'Food'">
                  <h2>Hot & Healthy</h2>
                  <h2>Food</h2>
                  <button class="btn food-btn" (click)="foodOpen(category)">Shop now</button>
              </ng-container>
          </div>
      </div>
  </div>
  <!-- cards -->
  <div class="custom-carousel-container gap-lg-4 mt-4" style="position: relative;" *ngIf="offers.length>0">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 mb-4" *ngFor="let offer of offers.slice(currentStartIndex, currentStartIndex + numVisible)">
          <div  class="card mx-2 cursor" style="cursor: pointer;" [ngStyle]="{ 'background-color': offer.backgroundColor }" (click)="category_card(offer.id,offer.categoryid,offer.categorykey,offer.text1,offer.parentid)">
            <div class="cards-food justify-content-between pe-lg-1">
              <div class="cards-image">
                <img [src]="offer.src"  class="img-fluid rounded-start" alt="Offer Image"/>
 
              </div>
 
              <div class="scroll-card">
                <div class="card-body pe-2">
                  <div class="card-text">
                    <span [ngStyle]="{ 'color': offer.highlightColor }" class="cards-upto pe-lg-2">
                      Up to
                    </span>
                    <span [ngStyle]="{ 'color': offer.textColor }" class="cards-offer ms-1">
                      {{ offer.text1 }}
                    </span>
                  </div>
                  <div class="card-text">
                    <span [ngStyle]="{ 'color': offer.highlightColor }" class="cards-upto">
                      On
                    </span>
                  </div>
                  <div>
                    <span [ngStyle]="{ 'color': offer.textColor }" class="cards-offer">
                      {{ offer.text2 }}
                    </span>
                  </div>
                  <div class="card-text">
                    <small [ngStyle]="{ 'color': offer.highlightColor }" class="cards-date">
                      {{ offer.offerDetails }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
      <!-- Left Arrow (Prev) -->
      <button *ngIf="showPrevArrow" (click)="navigateToPrev()" class="prev-arrow" style="position: absolute; left: 10px; top: 45%; transform: translateY(-50%);">
        <img src="../../assets/images/arrow-left.svg" alt="Previous" />
      </button>
   
      <!-- Right Arrow (Next) -->
      <button *ngIf="showNextArrow" (click)="navigateToNext()" class="next-arrow" style="position: absolute; right: 10px; top: 45%; transform: translateY(-50%);">
        <img src="../../assets/images/carousel.svg" alt="Next" />
      </button>
    </div>

  <div class=" arrow" [ngClass]="{'visible': showArrow}" (click)="scrollToTop()">
      <img src="../../assets/images/scroll-top-arrow.svg" alt="">
  </div>


  <app-explore-by-categories></app-explore-by-categories>

  <!-- <app-best-selling-products></app-best-selling-products> -->
  <!-- <app-best-selling-foods></app-best-selling-foods> -->

  <!--  -->
  <div *ngFor="let data of  mainCategories; let j= index">
      <app-best-selling [data]="data"></app-best-selling>
  </div>

  <!--  -->
  <div class="border"></div>
  <app-subscribe-product></app-subscribe-product>
  <app-recently-viewed-products *ngIf="userId != null"></app-recently-viewed-products>

  <app-play-store></app-play-store>
</div>


import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, ViewChild, Input,ChangeDetectorRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgImageSliderComponent } from 'ng-image-slider';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { ProductPopupDialogComponent } from '../product-popup-dialog/product-popup-dialog.component';
import { ProductService } from '../services/product.service';
declare var $: any;
 
@Component({
  selector: 'app-similar-product',
  templateUrl: './similar-product.component.html',
  styleUrls: ['./similar-product.component.css']
})
export class SimilarProductComponent {
  @Input() categoryId: any[] = [];
  @Input() productId: any;
 
  @Input() dataFromPreviousComponent: string | undefined;
  responsiveOptions: any[] | undefined;
  productList: any[] = [];
  productIds: string[] = [];
  numVisible: number = 4; // default visible items
  mrpPrice: number | undefined;
  priceToShow: number | undefined;
  selectedVariation: any;
  userId: string | null = null;
  add_cart: boolean = true;
  isLoggedIn: boolean = false;
  loadMoreRecentlyViewed: boolean = false;
  isLoadingRecentlyViewed: boolean = false;
  quantities = new Map<number, number>();
  addedToCart = new Map<number, boolean>();
  count: number = 4; // Default number of products to show
  page: number = 1;
  downArrow: boolean = false;
  upArrow: boolean = false;
  rightArrow: boolean = false;
  productListCount: any
 
  wishlistIcon = false;
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  isMobileView = false;
  issubscribe= false;
  issale= false;
  @ViewChild('elemt', { static: false }) parentElement!: ElementRef;
 
  @ViewChild('elemt') elemt!: ElementRef;
 
  constructor(public router: Router, private http: HttpClient, private productService: ProductService, private dialog: MatDialog, public auth: AuthService, private cartService: CartService,private cdr: ChangeDetectorRef
    ) {
    this.auth.accessToken.subscribe((token: string | null) => {
      // Set isLoggedIn to true if token exists, otherwise false
      this.isLoggedIn = !!token;
    });
    this.auth.userInfo.subscribe((data: any) => {
      if (data) {
        this.userId = data?.id;
        this.isLoggedIn = !!data;
      } else {
        this.isLoggedIn = false;
      }
    });
 
  }
  ngOnInit() {
    if (!this.isLoadingRecentlyViewed) {
      this.ProductListCall(this.count, "scroll", '', '');
 
    }
    this.updateVisibleItems();
    this.isMobileView = window.innerWidth < 993;
 
  }
 
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVisibleItems();
    this.isMobileView = window.innerWidth < 993;
 
  }
 
  // openProductDialog(product: any, selectedVariationId: string): void {
 
  //   const subscribeProduct = "subscribeProduct";
  //   const variationId = this.selectedVariation || selectedVariationId;
  //   this.dialog.open(ProductPopupDialogComponent, {
  //     data: {
  //       id: product.id,
  //       datafrom: subscribeProduct,
  //       variationId: variationId
  //     },
  //     width: '100%',
  //     height: '100%',
  //     disableClose: true
  //   });
  //   this.selectedVariation = ''
  // }
 
  updateVisibleItems() {
    const width = window.innerWidth;
    if (width > 1199) {
      this.numVisible = 4;
    } else if (width > 991) {
      this.numVisible = 3;
    } else if (width > 767) {
      this.numVisible = 2;
    } else {
      this.numVisible = 1;
    }
  }
 
  get showPrevArrow(): boolean {
    return this.productList.length > this.numVisible;
  }
 
  get showNextArrow(): boolean {
    return this.productList.length > this.numVisible;
  }
  selectSize(productId: any, variation: any): void {
    const product = this.productList.find((item: any) => item.id === productId);
 
    if (product&& product.productvariations && Array.isArray(product.productvariations)) {
      // Update selected variation details
      this.selectedVariation = variation;
      product.selectedVariation = variation.productvariationid;
 
      let variationValue: any;
    if (this.dataFromPreviousComponent === 'bestSelling' && variation.issale === true) {
      variationValue = variation.sellingprice;
    } else if (this.dataFromPreviousComponent === 'subscribeProduct' && variation.issubscribe === true) {
      variationValue = variation.priceupto7days;
    } else {
      // If neither condition is met, skip this variation
      return;
    }
 
    product.price = variationValue ?? 0; // Default to 0 if no valid price
    product.originalPrice = variation.mrpprice ?? 0;
 
 
      // Use the variation object directly for cartitemsquantity
      if (variation?.cartitemquantity != null) {
        product.cartitemquantity = variation.cartitemquantity;
      } else {
        // Fallback to productvariations if needed
        const selectedVariation = product.productvariations.find(
          (v: any) => v.productvariationid === variation.productvariationid
        );
        product.cartitemquantity = selectedVariation?.cartitemquantity ?? 0;
      }
      // const selectedVariation = product.variations.find(
      //   (v: any) => v.productvariationid === variation.productvariationid
      // );
      // product.wishlistIcon = selectedVariation?.wishlistIcon ?? false;
      // Update wishlist status and icon
      product.wishlistitemstatus =
      variation?.wishlistitemstatus ??
      false; // Default to false if null or undefined
 
    product.wishlistIcon =
      variation?.wishlistitemstatus === 1 ||
      variation?.wishlistIcon === true; // Use wishlistIcon directly if available
     
      // Update button visibility based on cart quantity
      this.addedToCart.set(productId, product.cartitemquantity > 0);
 
      // Trigger UI change detection
      this.cdr.detectChanges();
 
    }
  }

  startDragging(e: any, flag: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, type: string, child: any, spot: string) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.cursorDown.x = e.pageX;
    this.cursorDown.y = e.pageY;
    if (type === 'mouse') {
      this.startX = e.pageX - el.offsetLeft;
    } else {
      // this.startX = e.touches[0].pageX - el.offsetLeft;
      // this.startY = e.touches[0].pageY - el.offsetTop;
    }
 
 
 
    this.scrollLeft = el.scrollLeft;
    // setTimeout(() => { this.isDragging = true }, 300);
  }
 
  stopDragging(e: any, flag: any, type: string) {
    this.cursorUp.x = e.pageX;
    this.cursorUp.y = e.pageY;
    // setTimeout(() => { this.isDragging = false });
    this.mouseDown = false;
  }
 
  moveEvent(e: any, el: { offsetLeft: number; scrollLeft: number; offsetTop: number }, type: string, child: any) {
    // e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    let x = 0, y = 0;
    if (type === 'mouse') {
      x = e.pageX - el.offsetLeft;
    } else {
      // x = e.touches[0].pageX - el.offsetLeft;
      // y = e.touches[0].pageY - el.offsetTop;
    }
    const scrollX = x - this.startX;
    const scrollY = y - this.startY;
    if (Math.abs(scrollY) > Math.abs(scrollX)) {
      document.body.scrollTo(window.scrollX, window.scrollY - scrollY);
    } else {
      el.scrollLeft = this.scrollLeft - scrollX;
    }
  }
 
 
  onScroll(e: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, child: any) {
    if ((child.clientWidth - el.scrollLeft) <= el.clientWidth + 1) {
      // this.loadMoreProducts();
 
      // if (this.showMoreReview && !this.reviewLoading) {
      //   this.reviewLoading = true;
      //   this.loadmore();
      // }
    }
  }
 
  loadMoreProducts(productIds: string[], datafrom: any, parentElement: any, rightScroll: any): void {
 
    if (!this.isLoadingRecentlyViewed && this.loadMoreRecentlyViewed) {
 
      this.ProductListCall(1, datafrom, parentElement, rightScroll);
    }
  }
 
 
onScrollOrClick() {
  if (this.isLoadingRecentlyViewed) {
    return ; // Ignore further clicks while loading
  }
  this.upArrow = true;
  if (this.page === 1) {
    this.page = 5; // Start from 5 on the first click after the initial load
  } else {
    this.page += 1; // Increment the page count on subsequent clicks
  }
  // this.page += 1;
  // this.count =4;
  const container = this.elemt.nativeElement;
  setTimeout(() => {
    if (this.productListCount === this.productList.length) {
      this.downArrow = false;
      this.rightArrow = false;
    }
  }, 0);
  if (this.productList.length > 0 ) {
    this.downArrow = true;  // Enable down arrow
  }
 
  else{
    this.downArrow = false;
      // Enable down arrow
  }
  let parentElement = this.elemt.nativeElement.parentElement;
  let rightScroll = container.offsetWidth - container.offsetLeft;
  this.loadMoreProducts(this.productIds, "scroll", parentElement, rightScroll);
  setTimeout(() => {
    $(parentElement)[0].scrollTo({
      left: rightScroll, // Desired scrollLeft value
      behavior: 'smooth' // Enables smooth scrolling
    });
  });
 
}
 
onLeftScrollOrClick(): void {
  this.downArrow = false;
  this.rightArrow = true;
 
  const leftBtn = document.querySelector('#left-similarProduct') as HTMLElement;
  const content = document.querySelector('#similarProduct-list') as HTMLElement;
 
  const scrollLeft = (event: Event) => {
    const TOLERANCE = content.offsetLeft ;
    const scrollLeftMax = content.scrollWidth - content.clientWidth;
    // Check if we are at the start of the scrollable content
    if (content.scrollLeft <= 0) {
      this.downArrow = false; // No more space to scroll left
      this.rightArrow = true;
      this.upArrow = true;
    this.upArrow = false;
    }else {
      this.upArrow = content.scrollLeft > 300 - TOLERANCE;
    }
 
    content.scrollBy({
      left: -300,
      behavior: 'smooth'
    });
 
    event.preventDefault();
  };
 
  leftBtn.removeEventListener("click", scrollLeft);
  leftBtn.addEventListener("click", scrollLeft);
  scrollLeft(new MouseEvent('click'));
}
 
onRightScrollOrClick(): void {
  this.rightArrow = true;
  this.upArrow = true;
  const rightBtn = document.querySelector('#right-similarProduct') as HTMLElement;
  const content = document.querySelector('#similarProduct-list') as HTMLElement;
 
  const scrollRight = (event: Event) => {
    const maxScrollLeft = content.scrollWidth - content.clientWidth;
    setTimeout(() => {
      if (this.productListCount === this.productList.length) {
        this.downArrow = false;
      }
    }, 0);
    // Check if we can scroll further
    if (content.scrollLeft + 300 >= maxScrollLeft) {
      this.rightArrow = false; // No more space to scroll
      if (this.productList.length > 0) {
        this.downArrow = true;  // Enable down arrow
      }
     
       else{
        this.downArrow = false;
// Enable down arrow
      }
    }
 
    content.scrollBy({
      left: +300,
      behavior: 'smooth'
    });
    event.preventDefault();
  };
 
  rightBtn.removeEventListener("click", scrollRight);
  rightBtn.addEventListener("click", scrollRight);
  scrollRight(new MouseEvent('click'));
}
 
  // onLeftScrollOrClick(): void {
 
  //   const leftBtn = document.querySelector('#left-subscribe') as HTMLElement;
  //   const content = document.querySelector('#subscribe-list') as HTMLElement;
  //   const scrollLeft = (event: Event) => {
  //     content.scrollBy({
  //       left: -300,
  //       behavior: 'smooth'
  //     });
  //     event.preventDefault();
  //   };
  //   leftBtn.removeEventListener("click", scrollLeft);
  //   leftBtn.addEventListener("click", scrollLeft);
  //   scrollLeft(new MouseEvent('click'));
  // }
  // onRightScrollOrClick(): void {
  //   const rightBtn = document.querySelector('#right-subscribe') as HTMLElement;
  //   const content = document.querySelector('#subscribe-list') as HTMLElement;
  //   const scrollRight = (event: Event) => {
  //     content.scrollBy({
  //       left: +300,
  //       behavior: 'smooth'
  //     });
  //     event.preventDefault();
  //   };
  //   rightBtn.removeEventListener("click", scrollRight);
  //   rightBtn.addEventListener("click", scrollRight);
  //   scrollRight(new MouseEvent('click'));
  // }
 
 
  ProductListCall(count: number, dataFrom: any, parentElement: any, rightScroll: any
  ): void {
    this.isLoadingRecentlyViewed = true;
 
    const requestBody: any = {
      status: 1,
      // isSale: true,
      // isSubscribe: true,
      categoryIds: this.categoryId,
      page: this.page,
      count: count,
      similarProductIds:[this.productId]
    };
    if (this.userId) {
      requestBody.userId = this.userId;
    }
    if (this.dataFromPreviousComponent === 'bestSelling') {
      requestBody.isSale= true;
      this.issale = true;
     
    }
    else if (this.dataFromPreviousComponent === 'subscribeProduct') {
      requestBody.isSubscribe= true;
      this.issubscribe = true;
    }
   
    this.http.post(environment.apiUrl + `/products/list`, requestBody, { responseType: 'json' }).subscribe({
     next: (data: any) => {
        if (data.status === 'SUCCESS') {
 
          data.products_output.forEach((product: any) => {
           
            if (product.productvariations && product.productvariations.length > 0) {
              product.productvariations.sort((a: any, b: any) => a.sellingprice - b.sellingprice);
              // product.variations = [];
              product.cartitemquantity = null;
              product.issale = false;
              product.issubscribe = false;
              product.productvariations.forEach((variation: any, index: number) => {
                let variationValue: any;
         
                // Check for 'bestSelling' or 'subscribeProduct' conditions and set variationValue
                if (this.dataFromPreviousComponent === 'bestSelling' && variation.issale === true) {
                  variationValue = variation.sellingprice;
                } else if (this.dataFromPreviousComponent === 'subscribeProduct' && variation.issubscribe === true) {
                  variationValue = variation.priceupto7days;
                } else {
                  // If neither condition is met, skip adding this variation
                  return;
                }
                if (product.productvariations[0]) {
                  product.issale = variation.issale || product.issale;
                  product.issubscribe = variation.issubscribe || product.issubscribe;
   
                  if (index === 0) {
                    if (!variation.cartitemid) {
                      variation.cartitemid = null; // Assign a default value to avoid undefined errors
                    }
                    // Set the first variation as default
                    product.originalPrice = variation.mrpprice;
                    product.variationvalue = variation.variationvalue;
                    product.price = variationValue;
                    product.variationshortname = variation.variationshortname;
                    product.selectedVariation = variation.productvariationid;
                    product.cartitemquantity = variation.cartitemquantity;
                    product.cartid = variation.cartid;
                    product.cartitemid = variation.cartitemid;
                    product.wishlistitemstatus = variation.wishlistitemstatus
                    product.wishlistIcon = variation.wishlistitemstatus
 
                  }
                 
                  // product.variations.push({
                  //   originalPrice: variation.mrpprice,
                  //   priceToShow: variation.sellingprice,
                  //   variationvalue: variation.variationvalue,
                  //   variationshortname: variation.variationshortname,
                  //   productvariationid: variation.productvariationid
                  // });
                }
          // Check if the variation is already added
          const variationExists = product.productvariations.some(
            (v: any) => v.productvariationid === variation.productvariationid
          );
 
          if (!variationExists) {
            product.productvariations.push({
              originalPrice: variation.mrpprice,
              priceToShow: variationValue,
              variationvalue: variation.variationvalue,
              variationshortname: variation.variationshortname,
              productvariationid: variation.productvariationid,
              cartitemquantity: variation.cartitemquantity || null,
              cartid : variation.cartid,
              cartitemid:variation.cartitemid,
              wishlistitemstatus : variation.wishlistitemstatus,
              wishlistIcon:product.wishlistitemstatus
 
            });
               
                if (variation.cartitemquantity) {
                  product.cartitemquantity = variation.cartitemquantity;
                }
              product.wishlistIcon=product.wishlistitemstatus
 
              }
              product.wishlistIcon=product.wishlistitemstatus
 
              });
              product.wishlistIcon=product.wishlistitemstatus
         
              // Set the first variation as default, if variations exist
              // if (product.productvariations.length > 0) {
              //   const defaultVariation = product.productvariations[0];
              //   product.originalPrice = defaultVariation.originalPrice;
              //   product.price = defaultVariation.priceToShow;
              //   product.variationvalue = defaultVariation.variationvalue;
              //   product.variationshortname = defaultVariation.variationshortname;
              //   product.selectedVariation = defaultVariation.productvariationid;
              //   product.cartid = defaultVariation.cartid;
               
              // }
            }
              // Sort variations by variationvalue in ascending order
              product.productvariations.sort((a: any, b: any) => {
                if (a.variationvalue < b.variationvalue) {
                  return a.sellingprice - b.sellingprice;
                  // return -1;
                }
                if (a.variationvalue > b.variationvalue) {
                  return a.sellingprice - b.sellingprice;
                  // return 1;
                }
                return a.sellingprice - b.sellingprice;
                // return 0;
              });
          });
         
 
          data.products_output.forEach((x: any) => {
            x.src = [];
          
            // Parse and use the `imageinfo` field
            if (x.imageinfo) {
              try {
                const imageInfoArray = JSON.parse(x.imageinfo);
                if (imageInfoArray.length > 0) {
                  const imageId = imageInfoArray[0].imageid; // Assuming you want the first image
                  if (imageId) {
                    // Fetch the image using `uploads/get`
                    this.http.get(environment.apiUrl + `/uploads/get?id=${imageId}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        x.src.push(res.uploads.base64data);
                      }
                    });
                  }
                }
              } catch (err) {
                console.error("Error parsing imageinfo JSON:", err);
              }
            }
          });
          
 
          if (dataFrom === "arrow" && this.productList.length > 0) {
            this.upArrow = true; // Show "See Less" after clicking "See More"
          }
          const filteredActivities = data.products_output.filter((activity: any) => {
            return this.productList != activity.id;
          });
 
          this.productList = [...this.productList, ...data.products_output];
          if (parentElement && parentElement != "" && rightScroll && rightScroll != "") {
            this.upArrow = true;
 
            setTimeout(() => {
              $(parentElement)[0].scrollTo({
                left: rightScroll, // Desired scrollLeft value
                behavior: 'smooth' // Enables smooth scrolling
              });
            });
 
          }
 
 
          this.productListCount = data.count
          if (this.productList.length < count * this.page) {
            this.loadMoreRecentlyViewed = false; // No more products to load
          } else {
            this.loadMoreRecentlyViewed = true; // Enable further loading
          }
 
          if (data.count > this.productList.length) {
            // this.loadMoreRecentlyViewed = true;
            // this.page += 1;
            // this.upArrow = true; // Hide both arrows if no new products
            this.downArrow = true;
          }
          else {
            // this.loadMoreRecentlyViewed = false;
            this.downArrow = false;
            this.upArrow = true;
          }
 
          this.isLoadingRecentlyViewed = false;
 
        }
      },
      error:(error) => {
        console.error("API Error:", error);
      }
  });
  }
 
   
  productList_page(productId: string, selectedVariation: string): void {
    const variationId = this.selectedVariation || selectedVariation;
    const bestSelling = "bestSelling";
    // const name = this.categoryname;
    this.router.navigate(['/product-pages', productId], {
      state: { dataToPass: { datafrom: bestSelling, variationId: variationId} }
    });
    // const subscribeProduct = "subscribeProduct";
 
    // const variationId = this.selectedVariation || selectedVariation;
    // this.router.navigate(['/product-pages', productId], {
    //   state: { dataToPass: { datafrom: subscribeProduct, variationId: variationId } }
    // });
  }
  SubcribeClick(product_id: any, selectedVariationId: any) {
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const variationId = this.selectedVariation || selectedVariationId;
 
    const subcribe = "SubcribeProduct"
    this.router.navigate(['/add-cart'], {
      state: {
        dataToPass: {
          datafrom: subcribe,
          product_id: product_id,
          variation_id: variationId
        }
      }
    });
  }
  subscribeList_page(): void {
    this.router.navigate(['/subscribe-list-pages']);
  }
  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
 
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
    }
     // Find product
     const product = this.productList.find(item => item.id === productId);
     if (product) {
       product.cartitemquantity  = quantity; // Update UI immediately
       this.addedToCart.set(productId, quantity > 0); // Show/hide quantity buttons
       // this.addedToCart.set(productId, true); // Show quantity buttons
       this.cdr.detectChanges(); // Trigger change detection
     }
   
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity,
      type:  "is_sale",
    };
 
    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe({
      next:(data: any) => {
        if (data.status === 'SUCCESS') {
         // Update cart ID and new quantity from API response
         const selectedVariation = product?.productvariations.find(
          (v: any) => v.productvariationid === productVariationId
        );
        if (selectedVariation) {
          selectedVariation.cartitemid = data.cartitems?.id;
            product.cartitemid = data.cartitems?.id;
            selectedVariation.cartid = data.cartitems?.cartid;
            product.cartid = data.cartitems?.cartid;
            selectedVariation.cartitemquantity = quantity; // Sync with server response
            product.cartitemquantity = quantity; // Sync with server response
   
        }
 
        // Update cart count in the service
        this.cartService.updateCartCount(data.newCartCount);
        this.cdr.detectChanges();
        }
      },
     error: (error: any) => {
        console.error("Error in cartList API call:", error);
      }
  });
  }
 
  getQuantity(productId: number): number {
 
    return this.quantities.get(productId) || 1;
 
  }
  increaseQuantity(productId: number, productVariationId: string) {
    const product = this.productList.find(item => item.id === productId);
    if (product) {
      // product.cartitemsquantity += 1;
      // product.cartitemsquantity = (product.cartitemsquantity || 0) + 1;
      product.cartitemquantity = product.cartitemquantity || 0;
      product.cartitemquantity += 1;
 
      this.Add_to_cart(productId, productVariationId, product.cartitemquantity);
      this.cdr.detectChanges(); // Trigger change detection
    }
  }
 
  decreaseQuantity(productId: number, productVariationId: string): void {
    const product = this.productList.find(item => item.id === productId);
    const productvariations = product.productvariations.find(
      (v: any) => v.productvariationid === productVariationId
    );
    if (product || productvariations) {
      // Ensure cartitemsquantity is initialized
      product.cartitemquantity = product.cartitemquantity || 0;
      productvariations.cartitemquantity = productvariations.cartitemquantity || 0;
 
      if (product.cartitemquantity > 1 || productvariations.cartitemquantity > 1) {
        // Decrease quantity
        product.cartitemquantity -= 1;
        productvariations.cartitemquantity -= 1;
 
        // Update the cart with the new quantity
        this.Add_to_cart(productId, productVariationId, productvariations.cartitemquantity);
      } else if (product.cartitemquantity === 1 || productvariations.cartitemquantity === 1) {
        // If quantity reaches 0, remove the item from the cart
        product.cartitemquantity = 0;
        productvariations.cartitemquantity = 0;
        this.deleteCartItem(product.cartitemid || productvariations.cartitemid,productvariations.cartid || productvariations.cartitemcartid); // Call the delete API\
  
        // Reset UI: Show "Add to Cart" button
        this.addedToCart.set(productId, false);
      }
 
      // Trigger change detection to reflect the updated quantity
      this.cdr.detectChanges();
    } else {
      console.error('Product not found for ID:', productId);
    }
  }
  scrollToRight(): void {
 
    if (this.parentElement) {
      this.parentElement.nativeElement.scrollLeft += 200; // Adjust this value as needed
    }
  }
 
  scrollToBottom(): void {
    if (this.parentElement) {
      this.parentElement.nativeElement.scrollTop += 200; // Use this for vertical scrolling
    }
  }
  deleteCartItem(cartitemIds: number | null,cartId: number | null): void {
    const requestBody = {
      updatedBy: this.userId,
      cartitemIds: [cartitemIds],
      cartId:cartId
    };
 
    this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe({
      next:(data: any) => {
        if (data.status === 'SUCCESS') {
          if (this.userId !== null && this.userId !== undefined) {
            this.cartService.updateCartCount(data.newCartCount);
          } else {
            console.error('User ID is null or undefined. Cannot update cart count.');
          }
        }
      },
      error:(error: any) => {
        console.error('An error occurred:', error);
      }
  });
  }
 
 
  toggleWishList(event: Event, product: any, productId: any, productVariationId: any): void {
    event.stopPropagation(); // Prevent triggering parent events
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const variation = product.productvariations.find((v: any) => v.productvariationid === productVariationId);
    if (!variation) {
      console.error('Variation not found for Product ID:', productId, 'Variation ID:', productVariationId);
      return;
    }
    const newStatus = !variation.wishlistIcon;
 
    // product.wishlistIcon = newStatus; // Update the wishlist icon status in UI
    variation.wishlistIcon = newStatus; // Update the UI state for the specific variation
    product.wishlistIcon = variation.wishlistIcon; // Update the product wishlist state
    if (this.userId) {
      const requestBody = {
        status: newStatus ? 1 : -1,
        createdBy: this.userId,
        userId: this.userId,
        productId: productId,
        productVariationId: productVariationId,
        type:'is_sale'
      };
 
      this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
        .subscribe({
         next: (data: any) => {
            // Handle the success response here if needed
          },
          error:(error) => {
            console.error("API Error:", error);
          }
    });
    } else {
      // If user is not logged in, navigate to the login page
      this.router.navigate(['/login']);
    }
  }
 
 
 
}
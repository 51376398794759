<div class="my-5" *ngIf="productList && productList.length > 0">
  <div class="header d-flex justify-content-between align-items-center">
    <h5 class="fs-md-5 ">Best Selling {{categoryname}}</h5>
    <!-- <h6 class="hover-img" (click)="viewAll()">View all <img src="../../assets/images/arrow.svg" alt="arrow"
        class="ms-2 arrow-img"></h6> -->
  </div>
  <div class="section mt-4">
    <div id="best-selling-list-{{categoryname}}">
      <div *ngIf="isMobileView; else webLayout" class="parent">
        <div class="parent" #elemt (mousedown)="startDragging($event, false, elemt,'mouse',child, '')"
          (mouseup)="stopDragging($event, false,'mouse')" (mouseleave)="stopDragging($event, false,'mouse')"
          (mousemove)="moveEvent($event, elemt, 'mouse', child)" (scroll)="onScrollOrClick()">
          <div class="child d-flex  position-relative" #child>
            <ng-container *ngTemplateOutlet="sharedContent"></ng-container>
          </div>
        </div>
      </div>
      <ng-template #webLayout>
        <div #elemt>
          <div class="child d-flex  position-relative   " #child>
            <ng-container *ngTemplateOutlet="sharedContent"></ng-container>
          </div>
        </div>
      </ng-template>
      <ng-template #sharedContent>

        <div class="card me-md-2 me-1 col-3 " *ngFor="let product of  productList,let i= index">
          <div class="d-flex mobile-position">
            <!-- <div class="offer">{{ product.offer }}</div>
                   -->
            <div *ngFor="let variation of product?.productvariations ">
              <span class="offer" *ngIf="variation?.discountpercentage > 0 && variation?.productvariationid === product?.selectedVariation">
                {{variation?.discountpercentage}}% Off
              </span>
            </div>
       
            <!-- <div class="offer">50% Off</div> -->
            <div *ngIf="categoryname === type">
              <div class="halal-offer px-2 d-block" *ngIf="product.ishalalfood">Halal Food <img
                  src="../../assets/images/halal-food.svg" alt="">
              </div>
            </div>


            <div class="hover-zoom" (click)="productList_page(product.id,product.selectedVariation )">
              <!-- <img [src]="product.imgSrc" class="card-img" alt="product">
                       -->
              <img [src]="product.src?.[0]" class="card-img" alt="product">
            </div>
            <div class="like-border me-5 d-none d-sm-block">
              <!-- If product is in the wishlist (heart icon) -->
              <span *ngIf="isLoggedIn && product.wishlistIcon; else noWishListIcon"
                (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                <img src="../../assets/images/heart-icon.svg" class="like" alt="heart-icon">
              </span>

              <!-- If product is not in the wishlist (border heart icon) -->
              <ng-template #noWishListIcon>
                <span (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                  <img src="../../assets/images/heart-border-web.png" class="like" alt="like">
                </span>
              </ng-template>

              <img src="../../assets/images/view-border.svg" class="view" alt="view"
                (click)="openProductDialog(product)">
            </div>


            <!-- for mobile -->
            <div class="mobile-like mt-2 d-lg-none d-block">

              <span *ngIf="isLoggedIn &&product.wishlistIcon; else noWishListIcon"
                (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                <img src="../../assets/images/heart-icon.svg" class="heartlike" alt="like">
              </span>

              <!-- If product is not in the wishlist (border heart icon) -->
              <ng-template #noWishListIcon>
                <span (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                  <img src="../../assets/images/heartborder.svg" class="like" alt="like">
                </span>
              </ng-template>
            </div>
          </div>
          <div class="card-body px-sm-2 ">
            <h6 class="card-title mb-md-2 mb-2 text-truncate" (click)="productList_page(product.id,product.selectedVariation )">{{ product.title }}</h6>
            <div class="size-selection d-flex align-items-center">
              <div class="sizes d-flex align-items-center">
                <button *ngFor="let variation of product.productvariations" (click)="selectSize(product.id, variation)"
                  class="btn size-btn me-1 d-flex align-items-center"
                  [ngClass]="{'selected-btn': variation.productvariationid === product.selectedVariation}">
                  {{ variation.variationvalue }} {{ variation.variationshortname }}
                </button>

              </div>
            </div>
            <div class="text-center d-md-flex justify-content-md-between align-items-center mt-2">
              <div class="mt-2">
                <!-- Display updated price and MRP price -->
                <p class="amount m-0">{{ product.price | currency:'GBP' }}</p>
                <span class="amount2 m-0"><del>{{ product.originalPrice | currency:'GBP' }}</del></span>
              </div>
              <div class="add-btn text-center" (click)="Add_to_cart(product.id,product.selectedVariation,1)"
                *ngIf="!isLoggedIn || product.cartitemquantity === null || product.cartitemquantity === 0 ">
                <a class="btn mt-1"><img src="../../assets/images/cart.svg" alt="cart" class="me-2">Add to Cart</a>
              </div>
              <div class="d-flex justify-content-between align-items-center add-border cart"
                *ngIf="isLoggedIn && product.cartitemquantity>0">
                <button class="btn p-0 border-0" (click)="decreaseQuantity(product.id, product.selectedVariation)"><img
                    src="../../assets/images/decrease_img.svg" alt="Decrease"
                    style="width: 20px; height: 20px; margin-left: 20px;"></button>
                <span class="px-2" style="font-size: 14px; ">{{ product.cartitemquantity }}</span>
                <button class="btn p-0 border-0" (click)="increaseQuantity(product.id,product.selectedVariation)"><img
                    src="../../assets/images/increase_img.svg" alt="Increase"
                    style="width: 20px; height: 20px; margin-right: 20px;"></button>
              </div>
            </div>
            <!-- </div> -->

          </div>

        </div>
      </ng-template>
    </div>
    <div class="d-lg-block d-none" *ngIf="productListCount > 4">
      <div class="see-more" (click)="onScrollOrClick()" *ngIf="downArrow">
        <img src="../../assets/images/carousel.svg" class="d-block mx-auto seemoreIcon" alt="downArrow" />
      </div>
      <div class="see-more" (click)="onRightScrollOrClick(categoryname)" *ngIf="rightArrow">
        <img src="../../assets/images/carousel.svg" class="d-block mx-auto seemoreIcon" alt="downArrow"
          id="right-selling-button-{{categoryname}}" />
      </div>
      <div class="see-less" (click)="onLeftScrollOrClick(categoryname)" *ngIf="upArrow">
        <img src="../../assets/images/arrow-left.svg" class="d-block mx-auto seemoreIcon" alt="upArrow"
          id="left-selling-button-{{categoryname}}" />
      </div>



    </div>

  </div>
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, ViewChild,ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef  } from '@angular/material/dialog';
 import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { FormsModule } from '@angular/forms';
 
@Component({
  selector: 'app-product-popup-dialog',
  templateUrl: './product-popup-dialog.component.html',
  styleUrls: ['./product-popup-dialog.component.css']
})
export class ProductPopupDialogComponent {
  flavorList: string[] = [];
  iscomboavailable = false;
 
  isSale: boolean | undefined;
  isSubscribe: boolean | undefined;
  mrpPrice: number | undefined;
  priceToShow!: number;
  variationDisplay: string | undefined;
  selectedVariation: any;
  variations: any[] = [];
  userId: string | null = null;
  productDetails: any = {};
  productVariations: any[] = [];
 dataFromPreviousComponent: string | undefined;
  mainImage: string = '';
  selectedIndex: number = 0;
  selectedSize = '50 ML';
  isExpanded = false;
  variationid:any;
  productImages: { src: string[] } = { src: [] };
  sanitizedDescription: SafeHtml = '';
  activeTab = 'description';
  product_list_Id: any;
  additionaldetails: any;
  showMore: boolean = false;
  isLoggedIn: boolean = false;
  startIndex: number = 0;
  endIndex: number = 5;
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  numVisible: number = 1;
  selectedFlavor: string = '';
  FoodDeliverySlot: boolean = false;
  isDeliverySchedule: boolean = false;
  type:any
  subcategoryId :any
     // Image sources for controls
     prevImageSrc: string = '../../assets/icon/carousel-down.svg';
     nextImageSrc: string = '../../assets/icon/carousel-down.svg';
 
  @ViewChild('tabSection', { static: false }) tabSection!: ElementRef;
  ishalalfood: boolean | undefined;
 
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private elementRef:ElementRef,
    public auth: AuthService,
    private cartService: CartService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ProductPopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  )
  {
    this.subcategoryId = data.id
    this.dataFromPreviousComponent =data.datafrom
    this.variationid  = data.variationId;
 
   
    // Check if dataToPass exists and access its properties safely
   
 
      if (this.dataFromPreviousComponent === 'subscribeProduct') {
        this.isDeliverySchedule = true;
        this.type = 'is_subscribe'
      } else {
        this.isDeliverySchedule = false;
        this.type = 'is_sale'
      }
      this.auth.accessToken.subscribe((token: string | null) => {
        // Set isLoggedIn to true if token exists, otherwise false
        this.isLoggedIn = !!token;
      });
      this.auth.userInfo.subscribe((data: any) => {
        if (data) {
          this.userId = data?.id;
    this.ProductListCall(this.subcategoryId);
         
          this.isLoggedIn = !!data;
        } else {
          this.isLoggedIn = false;  
        }
      });
 
  }
 
  ngOnInit(): void {
  }
 
  getKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }
 
  selectImage(index: number): void {
    this.mainImage = this.productImages.src[index];
    this.selectedIndex = index;
  }
 
  prevImage(): void {
   
    if (this.startIndex > 0) {
      this.startIndex--;
      this.endIndex--;
    }
    // if (this.startIndex > 0) {
    //   this.startIndex -= this.numVisible;
    // }
  }
 
  nextImage() {
    if (this.endIndex < this.productImages.src.length) {
      this.startIndex++;
      this.endIndex++;
   
    }
    // if (this.startIndex + this.numVisible < this.productImages.src.length) {
    //   this.startIndex += this.numVisible;
    // }
  }
  get visibleImages() {
    return this.productImages.src.slice(this.startIndex, this.startIndex + this.numVisible);
  }
 
  hoverImage(control: string, isHover: boolean) {
    if (control === 'prev') {
      this.prevImageSrc = isHover ? '../../assets/icon/color-carousel-down.svg' : '../../assets/icon/carousel-down.svg';
    } else if (control === 'next') {
      this.nextImageSrc = isHover ? '../../assets/icon/color-carousel-down.svg' : '../../assets/icon/carousel-down.svg';
    }
  }
 
 
 
 
 
  selectSize(variation: any): void {
    if (!variation) {
      console.error("Invalid variation passed to selectSize:", variation);
      return;
    }
 
    const product = this.productDetails;
 
    // Ensure product exists and has variations
    if (product && product.productvariations && Array.isArray(product.productvariations)) {
      this.selectedVariation = variation;
 
      // Set selected variation ID
      product.selectedVariation = variation.productvariationid;
 
      // Set price and MRP to display
      this.priceToShow = this.getPriceToShow(variation);
      this.mrpPrice = variation.mrpprice ?? 0;
 
      // Update cart items quantity for the selected variation
      if (variation.cartitemquantity != null) {
        product.cartitemquantity = variation.cartitemquantity;
      } else {
        // Fallback to find the selected variation from the product variations
        const selectedVariation = product.productvariations.find(
          (v: any) => v.productvariationid === variation.productvariationid
        );
        product.cartitemquantity = selectedVariation?.cartitemquantity ?? 0;
      }
 
      // Update combo availability
      this.iscomboavailable = variation.iscomboavailable === true;
   
      // Update delivery slot logic based on the variation name
      this.FoodDeliverySlot = variation.name === 'Food';
   
      // Update wishlist icon status
    //  product.wishlistitemstatus =
    //  variation?.wishlistitemstatus ??
    //  false; // Default to false if null or undefined
 
   product.wishlistitemstatus =
     variation?.wishlistitemstatus === 1
 
 
    } else {
      console.error("Invalid productDetails or missing productvariations:", this.productDetails);
    }
  }
 
 
  getPriceToShow(variation: any): number {
    if (variation.issubscribe && variation.issale) {
      if (variation.dataFromPreviousComponent === 'bestSelling') {
        this.FoodDeliverySlot=true
        this.priceToShow = variation.sellingprice; // Set priceToShow when dataFromPreviousComponent is 'bestSelling'
      } else if (variation.dataFromPreviousComponent === 'subscribeProduct') {
        this.priceToShow = variation.priceupto7days; // Set priceToShow when dataFromPreviousComponent is 'subscribe'
      } else {
        this.priceToShow = variation.priceupto7days; // Default to priceupto7days if both issubscribe and issale are true
      }
    } else if (variation.issubscribe) {
      this.priceToShow = variation.priceupto7days; // Set priceToShow if only issubscribe is true
    } else if (variation.issale ) {
      this.priceToShow = variation.sellingprice; // Set priceToShow if only issale is true
    } else {
      this.priceToShow = variation.mrpprice; // Default to mrpPrice if neither issubscribe nor issale is true
    }
    return this.priceToShow; // Return the updated priceToShow
  }
 
 
 
 
  ProductListCall(subcategoryId?: string): void {
    const id = subcategoryId || '';
    const userIdParam = this.userId ? `&userId=${this.userId}` : '';
    this.http.get(`${environment.apiUrl}/products/user-view-get?id=${id}${userIdParam}`).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
 
          const CategoryIds = data.products.categoryid || [];
          let productId = data.products.id;
 
          if (productId) {
          }
       
 
          this.flavorList = data.products.flavor || null;
 
          if (data.products && data.products.flavor && data.products.flavor.length > 0) {
            // this.selectedFlavor = data.products.flavor;
            this.FoodDeliverySlot = true;
          }
          const element = this.elementRef.nativeElement.querySelector('.scroll_top');
          if (element) {
            window.scroll(0, 0);
          }
          this.productDetails = data.products;
         
 
          if (data.products.ishalalfood === true) {
            this.ishalalfood = true;
          }
          else {
            this.ishalalfood = false;
          }
          this.productDetails.variations = [];
 
          const matchingVariation = this.productDetails.productvariations.find(
            (variation: any) => variation.productvariationid === this.variationid
          );
 
          if (matchingVariation) {
           
            this.productDetails.cartitemquantity = matchingVariation.cartitemquantity;
            this.productDetails.cartitemcartid = matchingVariation.cartitemcartid;
            this.productDetails.selectedVariation = matchingVariation.productvariationid;
            this.productDetails.wishlistitemstatus = matchingVariation?.wishlistitemstatus;
           
          }
          if (this.productDetails.productvariations && this.productDetails.productvariations.length > 0) {
 
            // this.productDetails.productvariations.sort((a: any, b: any) => a.sellingprice - b.sellingprice);
            const uniqueVariations = this.productDetails.productvariations.filter(
              (variation: any, index: number, self: any[]) =>
                index === self.findIndex(v => v.productvariationid === variation.productvariationid)
            );
           
            uniqueVariations.sort((a: any, b: any) => a.sellingprice - b.sellingprice);
            uniqueVariations.forEach((x: any) => {
             
         
              if (x.productvariationid && x.iscomboavailable === true) {
                this.iscomboavailable = true;
              }
              else {
                this.iscomboavailable = false;
 
              }
              if (this.dataFromPreviousComponent === 'subscribeProduct' && x.issubscribe === true) {
                // Filter for subscribeProduct
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice, sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale: x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent,
                  // name: this.category,
                  iscomboavailable: x.iscomboavailable,
                  wishlistitemstatus:x.wishlistitemstatus,
                  // wishlistitemstatus:this.productDetails.wishlistitemstatus
                });
                this.productDetails.variations.sort((a: any, b: any) => {
                  if (a.variationvalue < b.variationvalue) {
                    return a.sellingprice - b.sellingprice;
                    // return -1;
                  }
                  if (a.variationvalue > b.variationvalue) {
                    return a.sellingprice - b.sellingprice;
                    // return 1;
                  }
                  return a.sellingprice - b.sellingprice;
                  // return 0;
                });
              } else if (this.dataFromPreviousComponent === 'bestSelling' && x.issale === true) {
                // Filter for bestSelling
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice,
                  sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale: x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent,
                  // name: this.category,
                  iscomboavailable: x.iscomboavailable,
                  wishlistitemstatus:x.wishlistitemstatus,
 
                });
                // Sort variations by variationvalue in ascending order
                this.productDetails.variations.sort((a: any, b: any) => {
                  if (a.variationvalue < b.variationvalue) {
                    return a.sellingprice - b.sellingprice;
                    // return -1;
                  }
                  if (a.variationvalue > b.variationvalue) {
                    return a.sellingprice - b.sellingprice;
                    // return 1;
                  }
                  return a.sellingprice - b.sellingprice;
                  // return 0;
                });
 
              } else if (this.dataFromPreviousComponent === '' && x.issale === true) {
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice,
                  sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale: x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent,
                  // name: this.category,
                  iscomboavailable: x.iscomboavailable,
                  wishlistitemstatus:x.wishlistitemstatus,
 
                });
                // Sort variations by variationvalue in ascending order
                this.productDetails.variations.sort((a: any, b: any) => {
                  if (a.variationvalue < b.variationvalue) {
                    return a.sellingprice - b.sellingprice;
                    // return -1;
                  }
                  if (a.variationvalue > b.variationvalue) {
                    return a.sellingprice - b.sellingprice;
                    // return 1;
                  }
                  return a.sellingprice - b.sellingprice;
                  // return 0;
                });
 
              }
            })
 
            if (this.variationid) {
              const mathProductvariationId = this.productDetails.variations.find(
                (variation: any) => variation.productvariationid === this.variationid
              );
              this.selectedVariation = mathProductvariationId;
 
 
            } else if (this.productDetails.variations.length > 0) {
              this.selectedVariation = this.productDetails.variations[0]; // Set the first variation as default
 
 
            }
            else {
              this.selectedVariation = this.productDetails.productvariations[0];
 
            }
           
 
            // Set the default variation (first one)
            // this.selectedVariation = this.productDetails.productvariations[0];
 
            // Calculate the default price to show based on the first variation
            this.getPriceToShow(this.selectedVariation);
            this.mrpPrice = this.selectedVariation.mrpprice;
          }
 
          // Other code for description and image handling
          if (this.productDetails.description) {
            this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(this.productDetails.description);
          } else {
            this.sanitizedDescription = '';
          }
 
          let x = data.products.additionaldetails;
 
          try {
            if (x && x.trim() !== '') {
              this.additionaldetails = JSON.parse(x);
            } else {
              this.additionaldetails = {}; // or any default value
            }
          } catch (e) {
            console.error("Error parsing additionaldetails:", e);
            this.additionaldetails = {}; // Handle the error by assigning a default value
          }
 
          this.productImages.src = []; // Initialize the image array

          // Assuming `imageinfo` contains the necessary image data
          if (this.productDetails.imageinfo) {
            try {
              const imageInfoArray = JSON.parse(this.productDetails.imageinfo); // Parse the `imageinfo`
              
              imageInfoArray.forEach((image: any, index: number) => {
                if (image.imageid) {
                  this.http.get(`${environment.apiUrl}/uploads/get?id=${image.imageid}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      this.productImages.src.push(res.uploads.base64data);
                      
                      // Set the first image as the default main image
                      if (index === 0) {
                        this.mainImage = this.productImages.src[0];
                        this.selectedIndex = 0;
                      }
                    }
                  });
                }
              });
            } catch (err) {
              console.error("Error parsing imageinfo JSON:", err);
            }
          }
          
          // if (this.userId) {
          //   this.wish_list();
 
          // }
        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }
 
  Add_to_cart(productId: string | number, productVariationId: string, quantity: number) {
 
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
      return;
    }
   
    let requestBody= {
        type: this.type,
        createdBy: this.userId,
        userId: this.userId,
        productId: productId,
        productVariationId: productVariationId,
        quantity: quantity,
        flavor: this.selectedFlavor,
      };
   
   
    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const product = this.productDetails.productvariations?.find(
            (variation: any) => variation.productvariationid === productVariationId
          );
       
          if (product) {
            product.cartitemquantity = quantity;
            product.cartitemid = data.cartitems?.id;
            this.productDetails.cartitemquantity = quantity;
            this.productDetails.cartitemid = data.cartitems?.id;
 
            this.cartService.updateCartCount(data.newCartCount);
            this.cdr.detectChanges();
            if (data.cartitems && data.cartitems.flavor) {
              this.selectedFlavor = data.cartitems.flavor[0];
            }
          } else {
            console.error(`ProductVariation ${productVariationId} not found`);
            // Handle undefined productVariation
          }
         
         
        }
      },
      (error: any) => {
        console.error("Error in add-to-cart API call:", error);
      }
    );
  }
   
  increaseQuantity(productId: number, productVariationId: string) {
   
   
   
      const product = this.productDetails.productvariations.find(
        (variation: any) => variation.productvariationid === productVariationId
      );
      if (product) {
        product.cartitemquantity = (product.cartitemquantity || 0) + 1;
        this.Add_to_cart(productId, productVariationId, product.cartitemquantity);
        this.productDetails.cartitemquantity = product.cartitemquantity;
        this.cdr.detectChanges();
      }
   
  }
   
   
  decreaseQuantity(productId: number, productVariationId: string) {
      const product = this.productDetails.productvariations.find(
        (variation: any) => variation.productvariationid === productVariationId
      );
      if (product) {
        if (product.cartitemquantity > 1) {
          product.cartitemquantity -= 1;
          this.Add_to_cart(productId, productVariationId, product.cartitemquantity);
        } else {
          product.cartitemquantity = 0;
          this.deleteCartItem(product); // Call delete function for individual product
        }
        this.cdr.detectChanges();
      }
 
  }
  deleteCartItem(product: any): void {
   
    const requestBody: any = {
      updatedBy: this.userId,
      cartitemIds : [product.cartitemid],
      cartId: product.cartid || product.cartitemcartid  ,
    };
   
 
   
   
    this.http.post(`${environment.apiUrl}/cart/delete`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.cartService.updateCartCount(data.newCartCount);
   
            this.productDetails.cartitemquantity = 0;
   
          this.cdr.detectChanges();
        }
      },
      (error: any) => {
        console.error('An error occurred while deleting the cart item:', error);
      }
    );
  }
 
  SubcribeClick(product_id: any, selectedVariationId: any) {
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const variationId =  selectedVariationId;
 
    const subcribe = "SubcribeProduct"
    this.router.navigate(['/add-cart'], {
      state: {
        dataToPass: {
          datafrom: subcribe,
          product_id: product_id,
          variation_id: variationId
        }
      }
    });
    this.dialogRef.close();
 
  }
 
  // product get wishlist
  toggleWishList(event: Event, item: any): void {
    event.stopPropagation();
 
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
      return;
    }
    const requestBody = {
          status: !item.wishlistitemstatus ? 1 : -1,
          createdBy: this.userId,
          userId: this.userId,
          productId: item.id,
          productVariationId: item.selectedVariation,
          type: this.type,
        };
 
    this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
      .subscribe({
        next:(data: any) => {
          if (data.status === 'SUCCESS') {
            // if (item?.comboproducts) {
              item.wishlistitemstatus = item.wishlistitemstatus === null ? 1 : null;
            // } else {
              item.wishlistitemstatus = item.wishlistitemstatus ? 1 : null; // Sync wishlist status
            // }
          }
        },
        error:(error) => {
          console.error('API Error:', error);
        }
  });
  }
//  toggleWishList(event: Event, product: any, productId: any, productVariationId: any): void {
//   event.stopPropagation(); // Prevent triggering parent events
//   if (!this.userId || !this.isLoggedIn) {
//     // Redirect to the login page if the user is not logged in
//     this.router.navigate(['/login']);
//     return; // Stop further execution
//   }
//   const newStatus = !product.wishlistIcon;
 
//   product.wishlistIcon = newStatus; // Update the wishlist icon status in UI
//   if (this.userId) {
//     const requestBody = {
//       status: newStatus ? 1 : -1,
//       createdBy: this.userId,
//       userId: this.userId,
//       productId: productId,
//       productVariationId: productVariationId,
//       type:this.type
//     };
 
//     this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
//       .subscribe(
//         (data: any) => {
//           // Handle the success response here if needed
//         },
//         (error) => {
//           console.error("API Error:", error);
//         }
//       );
//   } else {
//     // If user is not logged in, navigate to the login page
//     this.router.navigate(['/login']);
//   }
// }
 
// wish_list(): void {
//   const requestBody = {
//     userId: this.userId,
//   };
 
//   this.http.post(environment.apiUrl + `/wishlistitems/list`, requestBody, { responseType: 'json' })
//     .subscribe(
//       (data: any) => {
//         if (data.status === 'SUCCESS') {
//           const wish_list_product = data.wishlistitems_output;
 
//           // Check if the product is in the wishlist
//           const isInWishlist = wish_list_product.some((item: any) => item.productid === this.productDetails.id);
 
//           // Set wishlistIcon property based on the result
//           this.productDetails.wishlistIcon = isInWishlist;
//         }
//       },
//       (error) => {
//         console.error("Wishlist API Error:", error);
//       }
//     );
// }
 
navigateToProductPage(selectedVariation:any): void {
 
  this.router.navigate(['/product-pages', this.data.id],{
    state: { dataToPass: { datafrom: this.dataFromPreviousComponent , variationId: this.data.variationId } }
  });
  this.dialogRef.close();
 
}
// update Flavor
onFlavorChange(event: Event, productDetails: any): void {
  let selectedFlavor = (event.target as HTMLSelectElement).value;

  this.selectedFlavor = selectedFlavor;

  const matchingVariation = productDetails.productvariations.find(
    (variation: any) =>
      variation.productvariationid === this.selectedVariation.productvariationid
  );

  if (matchingVariation && matchingVariation.cartitemcartid && matchingVariation.cartitemid !== null) {

    this.updateFlavor({
      cartItemId: matchingVariation.cartitemid,
      cartId: matchingVariation.cartitemcartid,
      flavor: selectedFlavor
    });
  } else {

  }
}

  updateFlavor(product: any): void {

    const requestBody: any = {
      updatedBy: this.userId,
      cartId: product.cartId,
      flavor: product.flavor
    };
    // Include optional fields if present
    if (product.cartItemId) {
      requestBody.cartitemId = product.cartItemId;
    }
    if (product.comboId) {
      requestBody.comboId = product.comboId;
    }
    if (product.productId) {
      requestBody.productId = product.productId;
    }


    this.http.post(environment.apiUrl + `/cart/update-flavor`, requestBody, { responseType: 'json' }).subscribe({
      next:(data: any) => {
        if (data.status === 'SUCCESS') {
        } else {
          console.warn('Flavor update returned with a non-success status:', data);
        }
      },
      error:(error: any) => {
        console.error('An error occurred while updating the flavor:', error);
      }
  });
  }
 
}
 
 
 
 
 
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { ProductPopupDialogComponent } from '../product-popup-dialog/product-popup-dialog.component';
import { CartService } from '../services/cart-service.service';
import { ProductService } from '../services/product.service';
 
@Component({
  selector: 'app-subscribe-list-product',
  templateUrl: './subscribe-list-product.component.html',
  styleUrls: ['./subscribe-list-product.component.css']
})
export class SubscribeListProductComponent {
 
  productList: any[] = [];
  page: number = 1; // Initial page number
  count: number = 8;
  productsPerPage: number = 8;
  totalPages: number = 0;
  totalProducts: any;
  selectedVariation: any;
  userId: string | null = null;
  add_cart: boolean = true;
  isLoggedIn: boolean = false;
  quantities = new Map<number, number>();
  addedToCart = new Map<number, boolean>();
 
  constructor(public auth: AuthService, public router: Router, private route: ActivatedRoute, private http: HttpClient, private productService: ProductService, private dialog: MatDialog, private elementRef: ElementRef,private cartService: CartService) {
    this.auth.accessToken.subscribe((token: string | null) => {
      // Set isLoggedIn to true if token exists, otherwise false
      this.isLoggedIn = !!token;
    });
    this.auth.userInfo.subscribe((data: any) => {
      if (data) {
        this.userId = data?.id;
        this.isLoggedIn = !!data;
      } else {
        this.isLoggedIn = false;  
      }
    });
  }
  ngOnInit() {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
          })
    this.route.queryParams.subscribe(params => {
      const searchQuery = params['q'];
      if (searchQuery) {
        this.ProductListCall(searchQuery);
 
 
      }
    });
    this.ProductListCall()
  }
 
  previousPage(): void {
    if (this.page > 1) {
      window.scroll(0, 0);
      this.page--;
      this.ProductListCall();
    }
  }
 
  nextPage(): void {
    if (this.page < this.totalPages) {
      window.scroll(0, 0);
      this.page++;
      this.ProductListCall();
    }
  }
 
  goToPage(page: number): void {
    if (page !== this.page) {
      window.scroll(0, 0);
      this.page = page;
      this.ProductListCall();
    }
  }
 
  getProductsForPage(page: number): any[] {
    // Mock function to get products. In real implementation, this should be fetched from the server.
    // Calculate start and end index based on the current page
    const start = (page - 1) * this.productsPerPage;
    const end = start + this.productsPerPage;
 
    // Here, you would return the actual products based on the start and end indexes
    return []; // Return actual products array based on start and end
  }
  openProductDialog(product: any): void {
    const subscribeProduct = "subscribeProduct";
   
    // const variationId = this.selectedVariation|| selectedVariationId;
   
    this.dialog.open(ProductPopupDialogComponent, {
      data: {
        id: product.id,
        datafrom: subscribeProduct,
        variationId: product.selectedVariation
      },
      width: '90%',
      height: '90%',
      disableClose: true
    });
    product.selectedVariation=''
  }
 
  selectSize(productId: any, variation: any): void {
    const product = this.productList.find((item: any) => item.id === productId);
   
    if (product) {
      // Update selected variation details
      product.selectedVariation = variation.productvariationid;
   
      // Handle price logic based on available fields
      if (variation?.priceToShow != null) {
        product.price = variation.priceToShow; // Use priceToShow if available
      } else if (variation?.priceupto7days != null) {
        product.price = variation.priceupto7days; // Fallback to priceupto7days
      } else if (variation?.mrpprice != null) {
        product.price = variation.mrpprice; // Fallback to mrpprice
      } else {
        product.price = 0; // Default to 0 if no price is found
      }
   
      product.originalPrice = variation.mrpprice;
      product.wishlistitemstatus =
      variation?.wishlistitemstatus ??
      false; // Default to false if null or undefined
 
    product.wishlistIcon =
      variation?.wishlistitemstatus === 1 ||
      variation?.wishlistIcon === true; // Use wishlistIcon directly if available
  // product.wishlistIcon = variation?.wishlistIcon ?? false;
      // Trigger UI change detection
      // this.cdr.detectChanges();
   
    }
  }
  ProductListCall(searchQuery?: string): void {
    // Define requestBody with a flexible type using an index signature
    const requestBody: { [key: string]: any } = {
      status: 1,
      isSubscribe: true,
      count:this.productsPerPage,
      page: this.page,
      userId  :this.userId
    };
 
    // Add the searchQuery to requestBody if it exists
    if (searchQuery) {
      requestBody['q'] = searchQuery;
    }
   
    this.http.post(environment.apiUrl + `/products/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.productList = data.products_output;
          this.totalProducts = data.count;  // Fetch total product count from API response
 
          // this.totalPages = Math.ceil(this.totalProducts / this.count);  // Calculate total pages
          // this.totalPages = this.totalProducts > 9 ? Math.ceil(this.totalProducts / this.productsPerPage) : 0;
          this.totalPages = Math.ceil(this.totalProducts / this.productsPerPage);
          // variations
 
          this.productList.forEach((product: any) => {
            if (product.productvariations && product.productvariations.length > 0) {
              product.productvariations.sort((a: any, b: any) => a.priceupto7days - b.priceupto7days);
 
              // product.variations = [];
              product.productvariations.forEach((variation: any, index: number) => {
 
                if (product.productvariations[0]) {
                  // Set the first variation as default
                  if (index === 0) {
                  product.originalPrice = variation.mrpprice;
                  product.variationvalue = variation.variationvalue;
                  product.price = variation.priceupto7days;
                  product.variationshortname = variation.variationshortname;
                  product.selectedVariation = variation.productvariationid
                  product.wishlistitemstatus = variation.wishlistitemstatus,
                  product.wishlistIcon = variation.wishlistitemstatus
                  // product.productvariations.push({
                  //   originalPrice: variation.mrpprice,
                  //   priceToShow: variation.priceupto7days,
                  //   variationvalue: variation.variationvalue,
                  //   variationshortname: variation.variationshortname,
                  //   productvariationid: variation.productvariationid,
                  //   wishlistitemstatus:variation.wishlistitemstatus
                  // });
                }
                }
               // Check if the variation already exists before pushing
              const variationExists = product.productvariations.some(
                (v: any) => v.productvariationid === variation.productvariationid
              );
              if (!variationExists) {
                product.productvariations.push({
                  originalPrice: variation.mrpprice,
                  priceToShow: variation.priceupto7days,
                  variationvalue: variation.variationvalue,
                  variationshortname: variation.variationshortname,
                  productvariationid: variation.productvariationid,
                  wishlistitemstatus:variation.wishlistitemstatus,
                  wishlistIcon:variation.wishlistitemstatus
               
                });
              }
            product.wishlistIcon=product.wishlistitemstatus
 
              });
            // Sort variations by variationvalue in ascending order
            product.productvariations.sort((a: any, b: any) => {
              if (a.variationvalue < b.variationvalue) {
                return a.priceupto7days - b.priceupto7days;
                // return -1;
              }
              if (a.variationvalue > b.variationvalue) {
                return a.priceupto7days - b.priceupto7days;
                // return 1;
              }
              return a.priceupto7days - b.priceupto7days;
              // return 0;
            });
          }
 
          });
 
 
          data.products_output.forEach((x: any) => {
            x.src = [];
          
            // Parse and use the `imageinfo` field directly
            if (x.imageinfo) {
              try {
                const imageInfoArray = JSON.parse(x.imageinfo);
                if (imageInfoArray.length > 0) {
                  const imageId = imageInfoArray[0].imageid; // Assuming you want the first image
          
                  if (imageId) {
                    // Fetch the image using `uploads/get`
                    this.http.get(environment.apiUrl + `/uploads/get?id=${imageId}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        x.src.push(res.uploads.base64data);
                      }
                    });
                  }
                }
              } catch (err) {
                console.error("Error parsing imageinfo JSON:", err);
              }
            }
          });
          
          
        }
      },
    );
  }
 
 
  productList_page(productId: string, selectedVariation: string): void {
   
    const subscribeProduct = "subscribeProduct";
    const variationId = this.selectedVariation||selectedVariation;
   
    this.router.navigate(['/product-pages', productId], {
      state: { dataToPass: { datafrom: subscribeProduct , variationId: variationId } }
    });
 
  }
 SubcribeClick(product_id: any, selectedVariationId: any) {
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const variationId = this.selectedVariation || selectedVariationId;
 
    const subcribe = "SubcribeProduct"
    this.router.navigate(['/add-cart'], {
      state: {
        dataToPass: {
          datafrom: subcribe,
          product_id: product_id,
          variation_id: variationId
        }
      }
    });
  }
  toggleWishList(event: Event, product: any, productId: any, productVariationId: any): void {
    event.stopPropagation(); // Prevent triggering parent events
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const variation = product.productvariations.find((v: any) => v.productvariationid === productVariationId);
    if (!variation) {
      console.error('Variation not found for Product ID:', productId, 'Variation ID:', productVariationId);
      return;
    }
    const newStatus = !variation.wishlistIcon;
    variation.wishlistIcon = newStatus; // Update the UI state for the specific variation
    product.wishlistIcon = variation.wishlistIcon; // Update the product wishlist state
   
   // Update the wishlist icon status in UI
    if (this.userId) {
      const requestBody = {
        status: newStatus ? 1 : -1,
        createdBy: this.userId,
        userId: this.userId,
        productId: productId,
        productVariationId: productVariationId,
        type:'is_subscribe'
      };
 
      this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
        .subscribe({
          next:(data: any) => {
            // Handle the success response here if needed
          },
          error:(error) => {
            console.error("API Error:", error);
          }
    });
    } else {
      // If user is not logged in, navigate to the login page
      this.router.navigate(['/login']);
    }
  }
 
 
}
 
 
 
 
 
 
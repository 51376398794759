import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.css']
})
export class PrivacyPolicyPageComponent {
  pageDataType: any;
  pageType:any;
  pageTitle:string=''
  imageUrl: string = '';
  constructor(private router: Router,private http: HttpClient,) {
    // Access the state passed from the previous component
    const navigation = this.router.getCurrentNavigation();
    this.pageDataType = navigation?.extras?.state?.['pageData']; // Extract the state data
  }
  ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
          })
 
    if (this.pageDataType) {
      this.openPage()
    } else {
      console.error('No page data found');
    }
    const fullUrl = this.router.url; 
    this.pageDataType = fullUrl.startsWith('/') ? fullUrl.slice(1) : fullUrl;
  }

  openPage(){
   
    const requestBody = {
      pageType:this.pageDataType
    };
    
    this.http.post(environment.apiUrl + `/pages/list`, requestBody, { responseType: 'json' })
    .subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          if (Array.isArray(data.pages_output) && data.pages_output.length > 0) {
            this.pageType = data.pages_output[0]; // Assign the first page type if it exists
            this.pageTitle = this.pageType.title;
            const imageInfo = JSON.parse(this.pageType.imageinfo || '[]'); // Parse the imageinfo JSON
            if (imageInfo.length > 0 && imageInfo[0].imageid) {
              const imageId = imageInfo[0].imageid;
  
              // Fetch the image using the image ID
              this.http.get(environment.apiUrl + `/uploads/get?id=${imageId}`).subscribe((res: any) => {
                if (res.status === 'SUCCESS') {
                  this.imageUrl = res.uploads.base64data; // Set the fetched image data\
                  
                }
              });
            }
          } else {
            console.warn("No pages found in pages_output.");
          }
        } else {
          console.error("API response status is not SUCCESS. Status:", data.status);
        }
      },
      error: (error) => {
        console.error("Wishlist API Error:", error);
      }
    });
  
  }

}

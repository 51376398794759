export const environment = {
    production: false,
    apiUrl: 'https://api.gpstore.amicodevelopment.net',
    // apiUrl: 'http://localhost:3059',
    Google_Client_Id:'138028886270-3a8pv7s0qf14fdcskl5b8cpn767ijk50.apps.googleusercontent.com',
    googleMapsApikey:'AIzaSyDNcQeVHVsjL0Egsx57dBRsMksXnkpJbJM',
    // STRIPE_PUBLISHABLE_KEY1: "pk_live_51LsnFjLW9shJTXnhYgnKu1AcmiH0QdCw7vNAwZv3FsPuldfNjHcrd63ua4rxOrQvQ5zI757A29G9F6M9sUH01PhT00C0W9VafM",
    STRIPE_PUBLISHABLE_KEY: "pk_test_51LsnFjLW9shJTXnhpsxuaqWKmtZBvmComj82DvOCULtRvaJPRYEgCGYjfZKVxZhgukpPzSY81V6F861XLUxWTdH0001Fn7L7jl",
    GOOGLE_MAP_API_KEY: "AIzaSyDNcQeVHVsjL0Egsx57dBRsMksXnkpJbJM",
};
